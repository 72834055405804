export default {
    'A server with that slug already exists!': 'Er bestaat al een server met deze slug!',
    'Ad Link URL': 'Voeg Link URL toe',
    'Ad Photo URL': 'Voeg Photo URL toe',
    Ads: 'Advertenties',
    Advanced: 'Geavanceerd',
    'Allow Suite Creation': 'Toestaan om een Privé Tribune te maken',
    'Are you sure you want to delete this document? It cannot be undone':
        'Ben je zeker dat je dit document wil verwijderen? Dit kan niet ongedaan worden gemaakt',
    'Are you sure you want to delete this server? It cannot be undone':
        'Ben je zeker dat je deze server wil verwijderen? Dit kan niet ongedaan worden gemaakt',
    Arena: 'Stadion',
    Asia: 'Azië',
    Away: 'Uit',
    'Away Team': 'Bezoekers',
    Cancel: 'Annuleer',
    Caption: 'Bijschrift',
    'Click the button below to cheer': 'Klik op onderstaande knop om te supporteren',
    'Click the button to mute yourself, if it is grey your cheering is not heard.':
        'Klik op de roze knop om je microfoon uit te schakelen. Bij een grijze knop wordt er niks verstuurd',
    'Coming Soon': 'Binnenkort',
    'Connection URL': 'Connectie URL',
    'Copied!': 'Gekopiëerd!',
    'Copy and Paste the Link below': 'Kopiëer en plak onderstaande link',
    'Could not connect to the host securely': 'Kan geen veilige verbinding maken',
    'Could not find the server or server went offline': 'Kan de server niet vinden of de server is offline',
    'Create a Suite': 'Maak een Privé Tribune',
    'Create a suite and talk privately with family and friends':
        'Maak een Privé Tribune en supporter privé met familie en vrienden',
    'Create New': 'Nieuw aanmaken',
    'Creating New Team': 'Maak een nieuw team',
    Custom: 'Gepersonaliseerd',
    Date: 'Datum',
    Delete: 'Verwijderen',
    'Document created successfully': 'Document succesvol aangemaakt',
    'Document deleted successfully': 'Document succesvol verwijderd',
    'Document updated successfully': 'Document succesvol opgeladen',
    "Don't Show Again": 'Niet meer tonen',
    'Editing {{name}}': '{{name}} is aan het bewerken',
    Email: 'E-mail',
    Europe: 'Europa',
    Fans: 'Supporters',
    'Find Your Game': 'Vind jouw wedstrijd',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        'Firefox wordt niet ondersteund, gebruik Chrome of Safari om verder te gaan.',
    Friends: 'Vrienden',
    'Friends List Enabled': 'Vriendenlijst is ingeschakeld',
    General: 'Algemeen',
    'Get Started': 'Aan de slag',
    'Hide Home/Away Titles': 'Thuis/Uit verbergen',
    Home: 'Thuis',
    'Home Team': 'Thuisploeg',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        'Als je geen geluid hoort, of het geluid is stil, probeer dan de microfoon aan en uit te zetten door op de roze knop te duwen.',
    'Image URL': 'Afbeelding URL',
    'In order to get started, you first need to select your game': 'Om te starten, selecteer jouw wedstrijd',
    'League Logo': 'Logo Competitie',
    'League Logo Url': 'Logo Competitie URL',
    'Logged in as {{email}}': 'Ingelogd als {{email}}',
    Login: 'Inloggen',
    Logout: 'Uitloggen',
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        'De verbinding werd verbroken. Controleer jouw internetverbinding en probeer later opnieuw.',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        'Je bent de eerste! Weet dat je niemand kan horen tot wanneer iemand jou vervoegt',
    Manual: 'Gebruiksaanwijzigingen',
    'Mute All Client Output': 'Alle output dempen',
    Name: 'Naam',
    'New Server': 'Nieuwe server',
    None: 'Geen',
    'North America': 'Noord-America',
    Notes: 'Opmerkingen',
    'Now that you have created a suite, invite your friends to join':
        'Nu je jouw Privé Tribune heb gecreëerd, kan je jouw vrienden uitnodigen',
    Ongoing: 'Bezig',
    OR: 'OF',
    Password: 'Paswoord',
    'Password Required': 'Paswoord nodig',
    Past: 'Afgelopen',
    'Person of Operations': 'Person of Operations',
    'Please use Safari on iOS': 'Gebruik Safari op iOS',
    'Press the button to begin cheering (microphone access required).':
        'Druk op de blauwe knop onderaan om te beginnen supporteren.',
    Private: 'Privé',
    Public: 'Publiek',
    Save: 'Opslaan',
    'Save as New': 'Opslaan als Nieuw',
    Scheduled: 'Gepland',
    'Second Ad Link URL': 'Second Ad Link URL',
    'Second Ad Photo URL': 'Second Ad Photo URL',
    'Server created successfully': 'Server succesvol gecreëerd',
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'De server is offline. Kan geen verbinding maken. Controleer jouw internetverbinding en prober later opnieuw',
    'Server Location': 'Server Locatie',
    'Server updated successfully': 'Server succesvol geüpdatet',
    Share: 'Delen',
    'Show Replies to Tweets in Feed': 'Antwoorden op Tweets tonen in Feed',
    Slug: 'Slug',
    'Staff Login': 'Staff Login',
    'Third Ad Link URL': 'Third Ad Link URL',
    'Third Ad Photo URL': 'Third Ad Photo URL',
    'This game is Private and not viewable to the public': 'Deze wedstrijd is privé en is niet zichtbaar voor publiek',
    Time: 'Tijd',
    'Twitter Handle (case-sensitive)': 'Twitternaam (hoofdlettergevoelig)',
    'Unable to find or connect to microphone': 'Kan microfoon niet vinden',
    Upcoming: 'Gepland',
    'Use the small button to turn off local audio output.':
        'Gebruik de kleine microfoonknop naast het roze pictogram om de lokale audio-uitvoer uit te schakelen ',
    Visibility: 'Zichtbaarheid',
    'Welcome to a Private Suite!': 'Welkom in jouw Privé Tribune!',
    'Welcome to HearMeCheer!': 'Welkom bij HearMeCheer!',
    'When the button is pink, the microphone is on and your cheering is live':
        'Als de knop roos is, staat jouw microfoon aan en kan je supporteren',
    'Your cheering and clapping can be heard by others while your mic is on.':
        'Alles wat je zegt, kan worden gehoord door jouw team wanneer je micro aan staat.',
    '{{friends}} Friends Cheering': '{{friends}} Supporterende vrienden',
};
