import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ModerateGame from './pages/ModerateGame';
import GameContextProvider from './providers/GameContext';
import { moderateRoutes } from '../core/RoutesMap';
import { useAuthContext } from '../auth/providers/AuthContextProvider';
import Handle404 from '../core/pages/Handle404';

const Routes = () => {
    const { isAuthed, company } = useAuthContext();
    if (!isAuthed || !company.id) {
        return <Handle404 />;
    }
    return (
        <Switch>
            <Route path={moderateRoutes.home}>
                <GameContextProvider>
                    <ModerateGame />
                </GameContextProvider>
            </Route>
        </Switch>
    );
};

export default Routes;
