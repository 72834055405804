//        "([\w .,?!'{}()+-:;]+)"
export default {
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'meow Server is offline. Could not establish connection. Please check your internet connection and try again later',
    'Unable to find or connect to microphone': 'meow Unable to find or connect to microphone',
    'Could not connect to the host securely': 'meow Could not connect to the host securely',
    'Server created successfully': 'meow Server created successfully',
    'Server updated successfully': 'meow Server updated successfully',
    'A server with that slug already exists!': 'meow A server with that slug already exists!',
    'Are you sure you want to delete this server? It cannot be undone':
        'meow Are you sure you want to delete this server? It cannot be undone',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        "meow Looks like you're the first one here. Just know that you won't hear anything until someone else joins",
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        'meow Looks like we unexpectedly lost connection. Please check your internet connection and try again later',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        'meow If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.',
    'Could not find the server or server went offline': 'meow Could not find the server or server went offline',
    'Find Your Game': 'meow Find Your Game',
    '{{friends}} Friends Cheering': 'meow {{friends}} Friends Cheering',
    'Click the button below to cheer': 'meow Click the button below to cheer',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        'meow Firefox is not supported, please switch to Chrome or Safari to continue.',
    'Please use Safari on iOS': 'meow Please use Safari on iOS',
    'Welcome to HearMeCheer!': 'meow Welcome to HearMeCheer!',
    Fans: 'meow Fans',
    Friends: 'meow Friends',
    'Document created successfully': 'meow Document created successfully',
    'Document updated successfully': 'meow Document updated successfully',
    Share: 'meow Share',
    General: 'meow General',
    Ads: 'meow Ads',
    Advanced: 'meow Advanced',
    'Press the button to begin cheering (microphone access required).':
        'meow Press the button to begin cheering (microphone access required).',
    "When the button is pink, the microphone is on and you're cheering is live":
        "meow When the button is pink, the microphone is on and you're cheering is live",
    'Click the button to mute yourself, if it is grey your cheering is not heard.':
        'meow Click the button to mute yourself, if it is grey your cheering is not heard.',
    'Use the small button to turn off local audio output.': 'meow Use the small button to turn off local audio output.',
    'Your cheering and clapping can be heard by others while your mic is on.':
        'meow Your cheering and clapping can be heard by others while your mic is on.',
    "Don't Show Again": "meow Don't Show Again",
    'Get Started': 'meow Get Started',
    'Document deleted successfully': 'meow Document deleted successfully',
    'Logged in as {{email}}': 'meow Logged in as {{email}}',
    'Staff Login': 'meow Staff Login',
    Email: 'meow Email',
    Password: 'meow Password',
    Login: 'meow Login',
    None: 'meow None',
    'Editing {{name}}': 'meow Editing {{name}}',
    'New Server': 'meow New Server',
    'Home Team': 'meow Home Team',
    'Away Team': 'meow Away Team',
    Name: 'meow Name',
    Slug: 'meow Slug',
    'League Logo Url': 'meow League Logo Url',
    Caption: 'meow Caption',
    Date: 'meow Date',
    Time: 'meow Time',
    Arena: 'meow Arena',
    'Ad Photo URL': 'meow Ad Photo URL',
    'Ad Link URL': 'meow Ad Link URL',
    'Second Ad Photo URL': 'meow Second Ad Photo URL',
    'Second Ad Link URL': 'meow Second Ad Link URL',
    'Third Ad Photo URL': 'meow Third Ad Photo URL',
    'Third Ad Link URL': 'meow Third Ad Link URL',
    'Twitter Handle (case-sensitive)': 'meow Twitter Handle (case-sensitive)',
    'Friends List Enabled': 'meow Friends List Enabled',
    'Connection URL': 'meow Connection URL',
    'Are you sure you want to delete this document? It cannot be undone':
        'meow Are you sure you want to delete this document? It cannot be undone',
    Cancel: 'meow Cancel',
    Save: 'meow Save',
    Logout: 'meow Logout',
    'Create New': 'meow Create New',
    'Creating New Team': 'meow Creating New Team',
    'Image URL': 'meow Image URL',
    Delete: 'meow Delete',
    Home: 'meow Home',
    Away: 'meow Away',
    'League Logo': 'meow League Logo',
};
