import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import { ExitToApp as ExitToAppIcon, PersonAdd as PersonAddIcon, VpnKey as VpnKeyIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import UserAvatar from '../components/UserAvatar';
import { accountRoutes, authRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../providers/AuthContextProvider';

const MenuItemLink = (props) => {
    const { ...menuItemProps } = props;
    return <MenuItem component={RouterLink} {...menuItemProps} />;
};

const AccountListItem = (props) => {
    const { account } = props;
    return (
        <MenuItem component={RouterLink} to={authRoutes.changeProperty.replace(':propertyId', account.id)}>
            <ListItemText primary={account.name} />
        </MenuItem>
    );
};

AccountListItem.propTypes = {
    account: PropTypes.object,
};

const AccountMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { company, profileCompanies, user } = useAuthContext();

    const open = Boolean(anchorEl);
    const otherAccounts = profileCompanies.filter((account) => account.id !== company.id);

    const handleClick = useCallback((evt) => {
        setAnchorEl(evt.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const renderAccount = useCallback((account) => <AccountListItem key={account.id} account={account} />, []);

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                        <UserAvatar name={user.displayName || user.email} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,

                        maxHeight: '80vh',
                        overflowY: 'auto',

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <ListItem>
                    <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                        {'Currently Signed-In As: '}
                    </Typography>
                </ListItem>
                <ListItem divider={Boolean(otherAccounts.length)}>
                    <ListItemAvatar>
                        <UserAvatar name={user.displayName || user.email} />
                    </ListItemAvatar>
                    <ListItemText primary={company.name} secondary={user.displayName || user.email} />
                </ListItem>

                {otherAccounts.length ? (
                    <ListItem>
                        <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                            {'Switch to:'}
                        </Typography>
                    </ListItem>
                ) : null}
                {otherAccounts.map(renderAccount)}

                <Divider />
                <MenuItemLink to={accountRoutes.create}>
                    <ListItemIcon>
                        <PersonAddIcon fontSize="small" />
                    </ListItemIcon>
                    Add Another Property
                </MenuItemLink>
                <MenuItemLink to={authRoutes.changePassword}>
                    <ListItemIcon>
                        <VpnKeyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Change Password</ListItemText>
                </MenuItemLink>
                <MenuItemLink to={authRoutes.signOut}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Sign-Out</ListItemText>
                </MenuItemLink>
            </Menu>
        </React.Fragment>
    );
};

export default AccountMenu;
