import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Stack } from '@mui/material';
import TextField from '../../core/inputs/TextField';
import SelectField from '../../core/inputs/SelectField';
import { useFormContext } from '../../core/providers/FormContext';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function UserForm() {
    const { hasRole } = useAuthContext();
    const { isCreating, fields, fieldChange } = useFormContext();
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            <TextField
                label={t('Email')}
                value={fields.email}
                fieldName={'email'}
                onChange={fieldChange}
                disabled={!isCreating}
            />

            <SelectField label={t('Role')} value={fields.role} fieldName={'role'} onChange={fieldChange}>
                {hasRole('super') && <MenuItem value="owner">{t('Owner')}</MenuItem>}
                <MenuItem value="admin">{t('Admin')}</MenuItem>
                <MenuItem value="moderator">{t('Moderator')}</MenuItem>
            </SelectField>
        </Stack>
    );
}

UserForm.propTypes = {};
UserForm.defaultProps = {};

export default UserForm;
