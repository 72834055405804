import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import FormLayout from '../layout/FormLayout';
import PageLayout from '../layout/PageLayout';
import { auth } from '@hearmecheer/shared/firebase/client';
import Button from '../../core/buttons/Button';
import TextField from '../../core/inputs/TextField';
import { useSnackbar } from '../../core/providers/SnackbarProvider';

function ResetPassword() {
    const history = useHistory();
    const { emitSuccess, emitError } = useSnackbar();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');

    const handleEmailChange = useCallback((e) => {
        const { value } = e.target;
        setEmail(value);
    }, []);

    const handleSubmit = useCallback(async () => {
        try {
            await auth.sendPasswordResetEmail(email);
            emitSuccess(
                "An Email was sent to your email address. Please remember to check your spam folder if you don't see it in your inbox",
            );
            history.push('/auth/sign-in');
        } catch (e) {
            emitError(e.message);
        }
    }, [email, emitError, emitSuccess, history]);

    return (
        <PageLayout pageTitle={t('Reset Password')}>
            <FormLayout>
                <Typography variant="body2">
                    {t("We'll send you an email with a link to reset your password")}
                </Typography>
                <TextField label="Email" value={email} onChange={handleEmailChange} />
                <Box textAlign="right">
                    <Button color="primary" size="large" onClick={handleSubmit}>
                        {t('Send Reset Password Email')}
                    </Button>
                </Box>
            </FormLayout>
        </PageLayout>
    );
}

ResetPassword.propTypes = {};
ResetPassword.defaultProps = {};

export default ResetPassword;
