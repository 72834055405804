import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import DeleteButton from '../components/DeleteButton';
import SaveButton from '../components/SaveButton';
import Button from '../../core/buttons/Button';
import { useFormContext } from '../../core/providers/FormContext';

function FormActions(props) {
    const form = useFormContext();
    const { t } = useTranslation();

    return (
        <Box display="flex" sx={{ mt: 4 }}>
            {!form.isCreating && form.cancel && <DeleteButton onDelete={form.deleteItem} />}
            <Box ml="auto">
                {form.cancel && (
                    <Button flat={true} onClick={form.cancel}>
                        {t('Cancel')}
                    </Button>
                )}
                <SaveButton
                    isCreating={form.isCreating}
                    onSave={form.isCreating ? form.createItem : form.updateItem}
                    onSaveNew={form.createItem}
                />
            </Box>
        </Box>
    );
}

export default FormActions;
