export default {
    'A server with that slug already exists!': 'スラッグ付きのサーバーが既に存在しています。!',
    'Ad Link URL': 'リンク広告 URL',
    'Ad Photo URL': '広告写真URL',
    Ads: '広告',
    Advanced: 'アドバンスド',
    'Are you sure you want to delete this document? It cannot be undone':
        'この書類を消してよろしいでしょうか？一度消すと取り消しは出来ません。',
    'Are you sure you want to delete this server? It cannot be undone':
        'このサーバーを削除しても大丈夫ですか？　一度削除すると、取り戻すことはできません。',
    Arena: '会場',
    Away: 'アウェー',
    'Away Team': 'アウェーチーム ',
    Cancel: 'キャンセル',
    Caption: 'キャプション',
    'Click the button below to cheer': '下のボタンをクリックして応援に参加しよう！',
    'Click the button to mute yourself, if it is grey your cheering is not heard.':
        'ピンクのボタンを押せば、自分の声はミュートされるよ！ボタンが灰色の時は、自分の応援は他のファンに聞こえないよ',
    'Coming Soon': 'Coming Soon',
    'Connection URL': '接続 URL',
    'Could not connect to the host securely': 'ホストサーバーへの接続安全性が確認できません。',
    'Could not find the server or server went offline': 'サーバーが見つからないか、サーバーがオフラインになりました。',
    'Create New': '新規作成',
    'Creating New Team': '新規チームを作成',
    Date: '日付',
    Delete: '消去',
    'Document created successfully': 'ドキュメントの作成に成功しました',
    'Document deleted successfully': 'ドキュメントの消去に成功しました',
    'Document updated successfully': 'Document updated successfully',
    "Don't Show Again": '今後表示しない',
    'Editing {{name}}': 'エディット中 {{name}}',
    Email: 'Email',
    Fans: 'ファン',
    'Find Your Game': '応援する試合を探そう！',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        ' Firefoxはサポートされていません。続行するにはChromeまたはSafariに切り替えてください.',
    Friends: '友人',
    'Friends List Enabled': '友人リスト機能',
    General: '一般',
    'Get Started': '開始',
    Home: 'ホーム',
    'Home Team': 'ホームチーム',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        '音声が聞こえない、または音量が小さい場合は、下のピンク色のボタンを押してマイクをオフにしてからもう一度オンにし直して下さい。この動作を何度か繰り返すと改善される場合があります.',
    'Image URL': 'イメージURL',
    'League Logo': 'リーグロゴ',
    'League Logo Url': 'リーグロゴ Url',
    'Logged in as {{email}}': '{{email}}でログイン中',
    Login: 'ログイン',
    Logout: 'ログアウト',
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        '予期せず接続が切断された可能性があります。 インターネット接続を確認して、後でもう一度やり直してください ',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        'あなたが本日最初の参加者です。他の参加者が接続するまで、少々お待ちください。',
    Name: '名前',
    'New Server': '新規サーバー',
    None: '無い',
    Password: 'パスワード',
    'Please use Safari on iOS': ' iOSでSafariを使用してください',
    'Press the button to begin cheering (microphone access required).': '青色のボタンを押して、応援を開始しよう！',
    Save: 'セーブ',
    'Second Ad Link URL': '2社目：広告リンクURL',
    'Second Ad Photo URL': '2社目：広告写真 URL',
    'Server created successfully': 'サーバーが上手く開設されました。',
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'サーバーがオフラインです。接続を確認できませんでした。インターネット接続を確認して、後でもう一度やり直して下さい',
    'Server updated successfully': 'サーバーが上手くアップデートされました。',
    Share: 'シェア',
    Slug: 'スラッグ',
    'Staff Login': 'スタッフログイン',
    'Third Ad Link URL': '3社目：広告リンクURL ',
    'Third Ad Photo URL': '3社目：広告写真URL ',
    Time: '時間',
    'Twitter Handle (case-sensitive)': 'Twitterハンドル名(case-sensitive)',
    'Unable to find or connect to microphone': 'マイクを見つけられません、マイクを起動できません。',
    'Use the small button to turn off local audio output.':
        'ピンクのボタン横にある小さなマイクボタンを押すことで、消音設定に',
    'Welcome to HearMeCheer!': 'SportaCheerへようこそ!',
    'When the button is pink, the microphone is on and your cheering is live':
        'ボタンがピンクの時、収音マイクはオンの状態であり、自分の応援が皆に届くようになってるよ',
    'Your cheering and clapping can be heard by others while your mic is on.':
        '重要: マイクをオンにした状態で行った発言は、他のファンや、選手に聞こえる可能性があるよ！ ',
    '{{friends}} Friends Cheering': '{{friends}} 応援中の友達',
};
