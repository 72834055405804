import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const SimpleDialog = ({ open, onClose, title, body, actions }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{typeof body === 'string' ? <DialogContentText>{body}</DialogContentText> : body}</DialogContent>
        <DialogActions>{actions}</DialogActions>
    </Dialog>
);

SimpleDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    body: PropTypes.node,
    actions: PropTypes.any,
};
SimpleDialog.defaultProps = {};

export default SimpleDialog;
