import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '../../core/buttons/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.error.light,
        },
    },
}));

function DeleteButton(props) {
    const { onDelete } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClick = useCallback(() => {
        if (window.confirm(t('Are you sure you want to delete this document? It cannot be undone'))) {
            onDelete && onDelete();
        }
    }, [onDelete, t]);

    return (
        <Button className={classes.root} startIcon={<DeleteIcon />} onClick={handleClick}>
            {t('Delete')}
        </Button>
    );
}

DeleteButton.propTypes = {
    onDelete: PropTypes.func,
};

export default DeleteButton;
