export default {
    'A server with that slug already exists!': 'Taki serwer już istnieje!',
    'Ad Link URL': 'Dodaj link',
    'Ad Photo URL': 'Dodaj adres zdjęcia',
    Ads: 'Reklamy',
    Advanced: 'Zaawansowane',
    'Allow Suite Creation': 'Pozwól na stworzenie pokoju',
    'An unexpected error occured when trying to connect to the server': 'Wystąpił błąd podczas łączenia z serwerem',
    'Are you sure you want to delete this document? It cannot be undone':
        'Na pewno chcesz usunąć dokument? Tej czynności nie można cofnąć',
    'Are you sure you want to delete this server? It cannot be undone':
        'Na pewno chcesz usunąć serwer? Tej czynności nie można cofnąć',
    Arena: 'Hala',
    Asia: 'Azja',
    Away: 'Goście',
    'Away Team': 'GOście',
    'Broadcast URL': 'Link do transmisji',
    Cancel: 'Anuluj',
    Caption: 'Opis',
    'Click the button below to cheer': 'Kliknij przycisk i zacznij kibicować',
    'Click the button to mute yourself, if it is grey your cheering is not heard.':
        'Kliknij różowy przycisk, by wyłączyć mikrofon. Jeśli jest szary, Twój doping nie jest słyszalny',
    'Coming Soon': 'Wkrótce',
    'Connection URL': 'Adres',
    'Copied!': 'Skopiowano',
    'Copy and Paste the Link below': 'Skopiuj i wklej poniższy link',
    'Could not connect to the host securely': 'Brak bezpiecznego połączenia',
    'Could not find the game or game went offline': 'Nie znaleziono gry lub jest nieaktywna',
    'Could not find the server or server went offline': 'Nie znaleziono serwera lub jest nieaktywny',
    'Create New': 'Stwórz nowe',
    'Create a Suite': 'Utwórz pokój',
    'Create a suite and talk privately with family and friends':
        'Utwórz pokój, by móc rozmawiać z przyjaciółmi na osobności',
    'Creating New Team': 'Utwórz nowy zespół',
    Custom: 'Dowolne',
    Date: 'Data',
    Delete: 'Usuń',
    'Document created successfully': 'Dokument utworzony',
    'Document deleted successfully': 'Dokument usunięty',
    'Document updated successfully': 'Dokument zaktualizowany',
    "Don't Show Again": 'Nie pokazuj więcej',
    'Editing {{name}}': 'Edytowanie {{name}}',
    Email: 'Adres e-mail',
    Europe: 'Europa',
    'Failed to connect to server. Please try again later': 'Nieudane połączenie z serwerem. Spróbuj ponownie później',
    Fans: 'Fani',
    'Find Your Game': 'Szukaj meczu',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        'Aplikacja nie działa w przeglądarce Firefox. Spróbuj w Chrome lub Safari.',
    Friends: 'Znajomi',
    'Friends List Enabled': 'Lista znajomych włączona',
    General: 'Ogólne',
    'Get Started': 'Początek',
    'Hide Home/Away Titles': 'Ukryj etykiety Gospodarze/Goście',
    Home: 'Gospodarze',
    'Home Team': 'Gospodarze',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        'Jeśli nie słyszysz dźwięku lub jest on zbyt cichy, spróbuj wyłączyć i włączyć mikrofon różowym przyciskiem poniżej.',
    'Image URL': 'Link do zdjęcia',
    'In order to get started, you first need to select your game': 'Aby rozpocząć, wybierz mecz',
    'League Logo': 'Logo ligi',
    'League Logo Url': 'Link do logo ligi',
    'Logged in as {{email}}': 'Zalogowany jako {{email}}',
    Login: 'Zaloguj',
    Logout: 'Wyloguj',
    'Looks like the server is too busy at the moment. Please try again at a later time':
        'Serwer zajęty. Spróbuj później',
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        'Utrata połączenia. Sprawdź swoje łącze internetowe i spróbuj ponownie.',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        'Chyba jesteś pierwszym kibicem. Usłyszysz dźwięk, gdy dołączą kolejni.',
    Manual: 'Ręcznie',
    'Mute All Client Output': 'Wycisz dźwięk',
    Name: 'Imię',
    'New Server': 'Nowy serwer',
    None: 'Brak',
    'North America': 'Ameryka Pn.',
    Notes: 'Notatki',
    'Now that you have created a suite, invite your friends to join':
        'Utworzyłeś prywatny pokój. Zaproś do niego swoich znajomych.',
    OR: 'LUB',
    Ongoing: 'Trwa',
    Password: 'Hasło',
    'Password Required': 'Hasło jest wymagane',
    Past: 'Przeszłość',
    'Person of Operations': 'Osoba operacyjna',
    'Please use Safari on iOS': 'Użyj przeglądarki Safari.',
    'Press the button to begin cheering (microphone access required).':
        'Naciśnij niebieski przycisk na dole ekranu i zacznij kibicowanie.',
    Private: 'Prywatny',
    Public: 'Publiczny',
    Save: 'Zapisz',
    'Save as New': 'Zapisz jako',
    Scheduled: 'Zaplanowano',
    'Second Ad Link URL': 'Drugi link reklamy',
    'Second Ad Photo URL': 'Drugi link zdjęcia',
    'Server Location': 'Lokalizacja serwera',
    'Server created successfully': 'Serwer utworzony',
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'Serwer poza zasięgiem. Nie udało się utworzyć połączenia. Sprawdź swoje łącze internetowe i spróbuj później.',
    'Server updated successfully': 'Serwer zaktualizowany',
    Share: 'Podziel się',
    'Show Replies to Tweets in Feed': 'Pokazuj w feedzie odpowiedzi na tweeta.',
    Slug: 'Slug',
    'Staff Login': 'Logowanie dla obsługi',
    'Your cheering and clapping can be heard by others while your mic is on.':
        'Kiedy masz włączony mikrofon, wszystko co powiesz mogą usłyszeć inni fani oraz zawodnicy.',
    'Third Ad Link URL': 'Trzeci link do reklamy',
    'Third Ad Photo URL': 'Trzeci link do zdjęcia',
    'This game is Private and not viewable to the public': 'To jest prywatny mecz.',
    Time: 'Czas',
    'Twitter Handle (case-sensitive)': 'Połączenie z Twitterem (uwaga na wielkie-małe litery)',
    'Unable to find or connect to microphone': 'Brak połączenia z mikrofonem',
    Upcoming: 'Nadchodzące',
    'Use the small button to turn off local audio output.':
        'Użył małego przycisku mikrofonu tuż obok różowej ikony, by wyłączyć swoje wyjście audio.',
    Visibility: 'Widoczność',
    'Welcome to HearMeCheer!': 'Witaj w HearMeCheer!',
    'Welcome to a Private Suite!': 'Witaj w Pokoju Prywatnym!',
    'When the button is pink, the microphone is on and your cheering is live':
        'Jeśli przycisk ma kolor różowy, mikrofon jest włączony, a Twoje kibicowanie jest słyszalne',
    '{{friends}} Friends Cheering': '{{friends}} znajomych kibicuje',
};
