import React, { useEffect } from 'react';
import debug from 'debug';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { useAuthContext } from '../providers/AuthContextProvider';

const logger = debug('SignOut.js');

function SignOut() {
    const { signOut } = useAuthContext();

    useEffect(() => {
        logger('Signing user out');
        signOut();
    }, [signOut]);

    return <LoadingBackdrop />;
}

export default SignOut;
