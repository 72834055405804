import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { auth, firebase } from '@hearmecheer/shared/firebase/client';
import { makeStyles } from '@mui/styles';
import GoogleIcon from '../icons/GoogleIcon';
import Button from '../../../core/buttons/Button';

const useStyles = makeStyles({
    root: {
        background: '#ffffff',
    },
});

function GoogleSignOnButton(props) {
    const { inOrUp } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSignIn = useCallback(() => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithRedirect(provider);
    }, []);

    useEffect(() => {
        auth.getRedirectResult()
            .then((result) => console.log('received redirect result', result))
            .catch((err) => console.error('redirect result error', err));
    }, []);

    return (
        <Button className={classes.root} size="large" fullWidth startIcon={<GoogleIcon />} onClick={handleSignIn}>
            {t('Sign-{{inOrUp}} With Google', { inOrUp })}
        </Button>
    );
}

GoogleSignOnButton.propTypes = {
    inOrUp: PropTypes.oneOf(['in', 'up']).isRequired,
};
GoogleSignOnButton.defaultProps = {};

export default GoogleSignOnButton;
