import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { green } from '@mui/material/colors';
import { IconButton } from '@mui/material';
import { styled } from '@mui/styles';

const useScript = (src, callback) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = callback;
        document.body.appendChild(script);
        return () => document.body.removeChild(script);
    }, [callback, src]);
};

const FrameWrapper = styled('div')({
    position: 'relative',
    display: 'inline-flex',
});

const TopRightButton = styled(IconButton)({
    position: 'absolute !important',
    top: 0,
    right: 0,
    // transform: 'translateX(-100%)',
    '& > svg': {
        fill: green[500],
    },
});

function HmcEmbed(props) {
    const { propertyId, eventId } = props;
    const { authUser } = useAuthContext();
    const ref = useRef();

    const iframeUrl = new URL(`/${propertyId}/${eventId}`, process.env.REACT_APP_EMBED_SERVER);
    const jsUrl = new URL(`/assets/${propertyId}/${eventId}/init.js`, process.env.REACT_APP_EMBED_SERVER);

    useScript(jsUrl.href, () => {
        window.hmc__setParticipantName(authUser.displayName);
    });

    const refresh = useCallback(() => {
        ref.current.src += '';
    }, []);

    return (
        <div>
            <FrameWrapper>
                <TopRightButton size="small" onClick={refresh}>
                    <RefreshIcon />
                </TopRightButton>
                <iframe
                    ref={ref}
                    title="HearMeCheer"
                    src={iframeUrl.href}
                    width={400 * 1.25}
                    height={125 * 1.25}
                    frameBorder="0"
                    allow="microphone"
                    id="hmc-app"
                />
            </FrameWrapper>
        </div>
    );
}

HmcEmbed.propTypes = {
    propertyId: PropTypes.string,
    eventId: PropTypes.string,
};
HmcEmbed.defaultProps = {};

export default HmcEmbed;
