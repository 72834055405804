import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormLayout from '../layout/FormLayout';
import { auth } from '@hearmecheer/shared/firebase/client';
import Button from '../../core/buttons/Button';
import PasswordField from '../../core/inputs/PasswordField';
import TextField from '../../core/inputs/TextField';
import { useSnackbar } from '../../core/providers/SnackbarProvider';

const doNothing = () => null;

function SignInForm() {
    const { emitError } = useSnackbar();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = useCallback(() => {
        auth.signInWithEmailAndPassword(email, password).catch((err) => emitError(err.message));
    }, [email, emitError, password]);

    const handleEmailChange = useCallback((event) => setEmail(event.target.value), []);

    const handlePasswordChange = useCallback((event) => setPassword(event.target.value), []);

    return (
        <form onSubmit={doNothing}>
            <FormLayout>
                <TextField label={t('Email')} value={email} onChange={handleEmailChange} />
                <PasswordField value={password} onChange={handlePasswordChange} onEnter={handleSubmit} />
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Link component={RouterLink} to="/auth/reset-password">
                        Forgot Password?
                    </Link>
                    <Button color="primary" size="large" onClick={handleSubmit}>
                        {t('Sign-In')}
                    </Button>
                </Box>
            </FormLayout>
        </form>
    );
}

SignInForm.propTypes = {};
SignInForm.defaultProps = {};

export default SignInForm;
