import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '../../core/inputs/TextField';
import { useFormContext } from '../../core/providers/FormContext';
import FormActions from '../layout/FormActions';
import { Stack } from '@mui/material';

function CompanyForm(props) {
    const form = useFormContext();
    const { t } = useTranslation();
    return (
        <Stack spacing={4}>
            <TextField label={t('Name')} fieldName={'name'} value={form.fields.name} onChange={form.fieldChange} />
            <FormActions />
        </Stack>
    );
}

CompanyForm.propTypes = {
    isCreating: PropTypes.bool,
    details: PropTypes.object,
    onChange: PropTypes.func,
};
CompanyForm.defaultProps = {};

export default CompanyForm;
