import React, { useCallback, useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import FixedActionButton from '../../core/buttons/FixedActionButton';
import { Typography } from '@mui/material';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';
import SimpleDialog from '../../core/dialogs/SimpleDialog';
import { rooms } from '@hearmecheer/shared/models';
import { useGameContext } from '../providers/GameContext';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function CreateRoom() {
    const { company } = useAuthContext();
    const { game } = useGameContext();
    const [name, setName] = useState('');
    const [visible, setVisible] = useState(false);

    const showCreateRoomForm = useCallback(() => {
        setVisible(true);
    }, []);

    const hideCreateRoomForm = useCallback(() => {
        setVisible(false);
    }, []);

    const handleNewRoomNameChange = useCallback((e) => {
        const { value } = e.target;
        setName(value);
    }, []);

    const handleNewRoomCreation = useCallback(async () => {
        const context = { propertyId: company.id, eventId: game.id };
        const body = { name: name };
        await rooms.save(rooms.makeRef(company.id, game.id, await rooms.genUniqueId(context, body)), body);
        setVisible(false);
        setName('');
    }, [company.id, game.id, name]);

    return (
        <div>
            <FixedActionButton onClick={showCreateRoomForm}>
                <AddIcon />
            </FixedActionButton>
            <SimpleDialog
                open={visible}
                onClose={hideCreateRoomForm}
                title={'Create New Room'}
                body={
                    <div>
                        <Typography variant={'body2'}>Enter a unique name for the room</Typography>
                        <TextField value={name} onChange={handleNewRoomNameChange} onEnter={handleNewRoomCreation} />
                    </div>
                }
                actions={
                    <div>
                        <Button flat onClick={hideCreateRoomForm}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={handleNewRoomCreation}>
                            Create
                        </Button>
                    </div>
                }
            />
        </div>
    );
}

CreateRoom.propTypes = {};
CreateRoom.defaultProps = {};

export default CreateRoom;
