import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { devRoutes } from '../core/RoutesMap';

const TestPage = () => <div>Test Page</div>;

const Routes = () => (
    <Switch>
        <Route to={devRoutes.test} component={TestPage} />
    </Switch>
);

Routes.propTypes = {};
Routes.defaultProps = {};

export default Routes;
