import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    Popover,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { ExitToApp as ExitToAppIcon, Flag as ReportIcon } from '@mui/icons-material';
import { participants } from '@hearmecheer/shared/models';
import { useGameContext } from '../providers/GameContext';
import { omit } from '@hearmecheer/shared/Dict';
import MicIcon from '../icons/MicIcon';
import VolumeSlider from '../../core/components/VolumeSlider';
import ControlsPopover from '../components/ControlsPopover';
import RaisedHandIcon from '../components/RaisedHandIcon';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import RoomSelectionPopover from '../popovers/RoomSelectionPopover';
import UserAvatar from '../../auth/components/UserAvatar';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { formatDistanceToNow } from 'date-fns';
import Button from '../../core/buttons/Button';

const makeBroadcastRoom = () => ({ listenGain: 1, micGain: 0 });
const makePrimaryRoom = () => ({ listenGain: 1, micGain: 1 });

const ParticipantListItem = (props) => {
    const { participant, ...listItemProps } = props;
    const { company, hasRole } = useAuthContext();
    const { game, roomList, search } = useGameContext();
    const { emitError } = useSnackbar();

    const [micAnchorEl, setMicAnchorEl] = useState(null);
    const [roomSelectionAnchorEl, setRoomSelectionAnchorEl] = useState(null);
    const [reportsAnchorEl, setReportsAnchorEl] = useState(null);

    const showMicGainSlider = useCallback((e) => {
        setMicAnchorEl(e.currentTarget);
    }, []);

    const hideMicGainSlider = useCallback(() => {
        setMicAnchorEl(null);
    }, []);

    const showRoomSelectionPopover = useCallback((e) => {
        setRoomSelectionAnchorEl(e.target);
    }, []);

    const hideRoomSelectionPopover = useCallback(() => {
        setRoomSelectionAnchorEl(null);
    }, []);

    const openReportsPopover = useCallback((e) => {
        setReportsAnchorEl(e.target);
    }, []);

    const closeReportsPopover = useCallback(() => {
        setReportsAnchorEl(null);
    }, []);

    const changePrimaryRoom = useCallback(
        async (nextPrimaryRoom) => {
            const primaryRoom = participant.primaryRoom;
            let roomsMap = { ...participant.rooms };

            // remove the room we're leaving from the feed unless
            // the room is also a broadcasting room
            if (game.broadcastRooms.includes(primaryRoom)) {
                roomsMap[primaryRoom].micGain = 0;
            } else {
                roomsMap = omit(roomsMap, [primaryRoom]);
            }

            // add the new room to the feed
            if (game.broadcastRooms.includes(nextPrimaryRoom)) {
                roomsMap[nextPrimaryRoom] = makeBroadcastRoom();
            } else {
                roomsMap[nextPrimaryRoom] = makePrimaryRoom();
            }

            await participants.update(participants.makeRef(company.id, game.id, participant.id), {
                primaryRoom: nextPrimaryRoom,
                rooms: roomsMap,
            });
        },
        [company.id, game.broadcastRooms, game.id, participant.id, participant.primaryRoom, participant.rooms],
    );

    const handleMicGainChange = useCallback(
        (gain) => {
            return participants.update(participants.makeRef(company.id, game.id, participant.id), {
                [`rooms.${participant.primaryRoom}.micGain`]: gain,
            });
        },
        [company.id, game.id, participant.id, participant.primaryRoom],
    );

    const handleMoveClick = useCallback(
        async (e) => {
            const otherRooms = roomList.filter((room) => room.id !== participant.primaryRoom && !room.isParty);
            switch (otherRooms.length) {
                case 0:
                    return emitError('You need to create more rooms before you can move this participant');
                case 1: {
                    const [nextRoom] = otherRooms;
                    await changePrimaryRoom(nextRoom.id);
                    break;
                }
                default:
                    showRoomSelectionPopover(e);
            }
        },
        [changePrimaryRoom, emitError, participant.primaryRoom, roomList, showRoomSelectionPopover],
    );

    const clearReports = useCallback(() => {
        return participants.update(participants.makeRef(company.id, game.id, participant.id), { reports: [] });
    }, [company.id, game.id, participant.id]);

    return (
        <ListItem {...listItemProps}>
            <ListItemAvatar>
                <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                    <UserAvatar
                        // isOnline={Boolean(participant.isOnline)}
                        src={participant.avatarUrl}
                        name={participant.name}
                    />
                </Badge>
            </ListItemAvatar>
            <Stack>
                <Stack spacing={1} direction="row">
                    <Typography sx={{ fontSize: '.8' }}>{participant.name}</Typography>
                    <Typography
                        color="textSecondary"
                        sx={{
                            fontSize: '.8rem',
                            ...(!hasRole('dev') && { display: 'none' }),
                        }}
                    >
                        ({participant.id})
                    </Typography>
                </Stack>
                <Typography color="textSecondary" sx={{ fontSize: '.8rem' }}>
                    {participant.partyRoom}
                </Typography>
            </Stack>
            <ListItemSecondaryAction>
                {participant.reports && participant.reports.length ? (
                    <React.Fragment>
                        <Tooltip title={'View Reports'}>
                            <IconButton color="error" onClick={openReportsPopover}>
                                <ReportIcon />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            anchorEl={reportsAnchorEl}
                            open={Boolean(reportsAnchorEl)}
                            onClose={closeReportsPopover}
                        >
                            <List sx={{ width: 400, p: 2 }}>
                                {participant.reports.map((report) => (
                                    <ListItem key={report.time} divider>
                                        <Link onClick={() => search('id=' + report.issuerId)} sx={{ mr: 2 }}>
                                            Participant
                                        </Link>
                                        {'Reported '}
                                        {formatDistanceToNow(report.time, {
                                            addSuffix: true,
                                        })}
                                    </ListItem>
                                ))}
                                <ListItem>
                                    <Button size="small" color="error" onClick={clearReports}>
                                        Clear All
                                    </Button>
                                </ListItem>
                            </List>
                        </Popover>
                    </React.Fragment>
                ) : null}

                {participant.isHandRaised && (
                    <IconButton>
                        <RaisedHandIcon />
                    </IconButton>
                )}
                <IconButton onClick={showMicGainSlider}>
                    <MicIcon volume={participant.rooms[participant.primaryRoom].micGain} />
                </IconButton>
                <ControlsPopover anchorEl={micAnchorEl} onClose={hideMicGainSlider}>
                    <VolumeSlider
                        value={participant.rooms[participant.primaryRoom].micGain}
                        onChange={handleMicGainChange}
                    />
                </ControlsPopover>

                <IconButton onClick={handleMoveClick}>
                    <ExitToAppIcon />
                </IconButton>
                <RoomSelectionPopover
                    anchorEl={roomSelectionAnchorEl}
                    onClose={hideRoomSelectionPopover}
                    currentRoom={participant.primaryRoom}
                    onSelect={changePrimaryRoom}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

ParticipantListItem.propTypes = {
    participant: PropTypes.object.isRequired,
};
ParticipantListItem.defaultProps = {};

export default ParticipantListItem;
