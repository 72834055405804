import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { automodRoutes } from '../core/RoutesMap';
import Home from './pages/Home';

const Routes = () => (
    <Switch>
        <Route path={automodRoutes.home} component={Home} />
    </Switch>
);
export default Routes;
