import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';

function SelectField(props) {
    const { id, label, fullWidth, fieldName, onChange, variant, helperText, ...otherProps } = props;

    const labelId = id && id + '-label';

    const handleChange = useCallback(
        (e) => {
            e.target.dataset = { field: fieldName };
            onChange(e);
        },
        [fieldName, onChange],
    );

    return (
        <FormControl variant={variant} fullWidth={fullWidth}>
            {label && <InputLabel id={labelId}>{label}</InputLabel>}
            <Select labelId={labelId} id={id} {...otherProps} onChange={Boolean(onChange) ? handleChange : null} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}

SelectField.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    gutter: PropTypes.any,
    fieldName: PropTypes.string,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    helperText: PropTypes.string,
};
SelectField.defaultProps = {
    id: uuid().split('-')[0],
    fullWidth: true,
    variant: 'outlined',
};

export default SelectField;
