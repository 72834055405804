export const accountRoutes = {
    list: '/accounts/list',
    create: '/accounts/create',
    edit: '/accounts/edit/:accountId',
    // get:
};

export const authRoutes = {
    home: '/auth',
    signOut: '/auth/sign-out',
    signUp: '/auth/sign-up',
    signIn: '/auth/sign-in',
    token: '/auth/token/:token',
    resetPassword: '/auth/reset-password',
    verifyEmail: '/auth/verify-email',
    changePassword: '/auth/change-password',
    changeProperty: '/auth/change-property/:propertyId',
};

export const automodRoutes = {
    home: '/automods',
};

export const apiRoutes = {
    keys: '/keys',
    sites: '/domains',
};

export const coreRoutes = {
    home: '/',
    error404: '/error/404',
};

export const devRoutes = {
    test: '/dev/test',
};

export const eventRoutes = {
    home: '/events',
    list: '/events/list',
};

export const moderateRoutes = {
    home: '/moderate/:eventId',
};

export const profileRoutes = {
    list: '/users',
    create: '/users/create',
    edit: '/users/edit/:userId',
};
