import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';
import SingleSignOnProviders from '../components/SingleSignOnProviders';
import PageLayout from '../layout/PageLayout';
import SignInForm from '../forms/SignInForm';

const SignIn = (props) => {
    const { t } = useTranslation();
    return (
        <PageLayout pageTitle={t('Sign-In')} aside={<SingleSignOnProviders inOrUp="in" />}>
            <SignInForm />
            <Box mt={1} textAlign="right">
                <Typography variant="body2">
                    {t("Don't Have an Account?")}&nbsp;
                    <Link component={RouterLink} to="/auth/sign-up">
                        {t('Sign-Up')}
                    </Link>
                </Typography>
            </Box>
        </PageLayout>
    );
};

export default SignIn;
