import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import SignOut from './pages/SignOut';
import Handle404 from '../core/pages/Handle404';
import VerifyEmail from './pages/VerifyEmail';
import ChangePassword from './pages/ChangePassword';
import ResetPassword from './pages/ResetPassword';
import { authRoutes } from '../core/RoutesMap';
import { useAuthContext } from './providers/AuthContextProvider';
import ChangeProperty from './pages/ChangeProperty';
import Token from './pages/Token';

const BaseRoutes = () => (
    <Switch>
        <Route path={authRoutes.changeProperty} component={ChangeProperty} />
        <Route path={authRoutes.token} component={Token} />
        <Route path={'*'} component={Handle404} />
    </Switch>
);

const GuestRoutes = () => (
    <Switch>
        <Route path={authRoutes.signUp} component={SignUp} />
        <Route path={authRoutes.signIn} component={SignIn} />
        <Route path={authRoutes.resetPassword} component={ResetPassword} />
        <Route component={BaseRoutes} />
    </Switch>
);

const UserRoutes = () => {
    const { authUser } = useAuthContext();
    return (
        <Switch>
            {!authUser.emailVerified && <Route path={authRoutes.verifyEmail} component={VerifyEmail} />}
            <Route path={authRoutes.changePassword} component={ChangePassword} />
            <Route path={authRoutes.signOut} component={SignOut} />

            <Route component={BaseRoutes} />
        </Switch>
    );
};

function AuthRoutes() {
    const { isAuthed } = useAuthContext();
    return isAuthed ? <UserRoutes /> : <GuestRoutes />;
}

export default AuthRoutes;
