import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { Group as GroupIcon, Podcasts as BroadcastIcon } from '@mui/icons-material';
import { useGameContext } from '../providers/GameContext';

function RoomSelectionPopover(props) {
    const { anchorEl, onClose, currentRoom, onSelect } = props;
    const { game, primaryRoomList } = useGameContext();

    const renderRoom = useCallback(
        (room) => {
            if (room.id === currentRoom) {
                return null;
            }
            return (
                <MenuItem key={room.id} onClick={() => onSelect(room.id)} data-roomId={room.id}>
                    {game.broadcastRooms.includes(room.id) && (
                        <ListItemIcon>
                            <BroadcastIcon color="primary" />
                        </ListItemIcon>
                    )}
                    {game.defaultRoom === room.id && (
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                    )}
                    <ListItemText primary={room.name} />
                </MenuItem>
            );
        },
        [currentRoom, game.broadcastRooms, game.defaultRoom, onSelect],
    );

    return (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
            {primaryRoomList.map(renderRoom)}
        </Menu>
    );
}

RoomSelectionPopover.propTypes = {
    anchorEl: PropTypes.element,
    onClose: PropTypes.func,
    currentRoom: PropTypes.string,
    onSelect: PropTypes.func,
};
RoomSelectionPopover.defaultProps = {};

export default RoomSelectionPopover;
