import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { useGameContext } from '../providers/GameContext';
import React, { useCallback, useEffect, useState } from 'react';
import { events } from '@hearmecheer/shared/models';
import { Badge, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography } from '@mui/material';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';
import PropTypes from 'prop-types';
import { Code as CodeIcon } from '@mui/icons-material';

const CustomCodeDialog = ({ onClose, ...dialogProps }) => {
    const { company } = useAuthContext();
    const { game } = useGameContext();
    const [tabIndex, setTabIndex] = useState(0);
    const [customStyles, setCustomStyles] = useState('');
    const [customScripts, setCustomScripts] = useState('');

    const changesMade = customStyles !== game.customStyles || customScripts !== game.customScripts;

    useEffect(() => {
        if (game.customStyles) {
            setCustomStyles(game.customStyles);
        }
    }, [game.customStyles]);

    useEffect(() => {
        if (game.customScripts) {
            setCustomScripts(game.customScripts);
        }
    }, [game.customScripts]);

    const handleTabChange = useCallback((e, nextValue) => setTabIndex(nextValue), []);

    const handleStylesChange = useCallback((e) => setCustomStyles(e.target.value), []);

    const handleScriptsChange = useCallback((e) => setCustomScripts(e.target.value), []);

    const handleClose = useCallback(() => {
        setCustomScripts(game.customScripts);
        setCustomStyles(game.customStyles);
        if (onClose) {
            onClose();
        }
    }, [game.customScripts, game.customStyles, onClose]);

    const saveCustomCode = useCallback(async () => {
        await events.update(events.makeRef(company.id, game.id), {
            customStyles: customStyles,
            customScripts: customScripts,
        });
        onClose();
    }, [company.id, customScripts, customStyles, game.id, onClose]);

    return (
        <Dialog
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    width: '70%',
                },
            }}
            {...dialogProps}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <CodeIcon />
                    <span>Custom Code</span>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab
                        label={
                            <Badge color="primary" variant="dot" invisible={customStyles === game.customStyles}>
                                <Typography>CSS</Typography>
                            </Badge>
                        }
                    />
                    <Tab
                        label={
                            <Badge color="primary" variant="dot" invisible={customScripts === game.customScripts}>
                                <Typography>JS</Typography>
                            </Badge>
                        }
                    />
                </Tabs>

                <div>
                    {tabIndex === 0 ? (
                        <TextField fullWidth multiline rows={10} value={customStyles} onChange={handleStylesChange} />
                    ) : (
                        <TextField fullWidth multiline rows={10} value={customScripts} onChange={handleScriptsChange} />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button flat onClick={handleClose}>
                    {!changesMade ? 'Close' : 'Discard Changes & Close'}
                </Button>
                <Button disabled={!changesMade} onClick={saveCustomCode}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CustomCodeDialog.propTypes = {
    onClose: PropTypes.func,
};

export default CustomCodeDialog;
