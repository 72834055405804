import PropTypes from 'prop-types';

function PluralLabel(props) {
    const { singular, number } = props;
    let plural = props.plural || singular + 's';

    if (number === 0) {
        return 'No ' + plural;
    }
    if (number === 1) {
        return '1 ' + singular;
    }
    return number + ' ' + plural;
}

PluralLabel.propTypes = {
    singular: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    plural: PropTypes.string,
};
PluralLabel.defaultProps = {};

export default PluralLabel;
