import debug from 'debug';
import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { auth, firebase } from '@hearmecheer/shared/firebase/client';
import { properties, profiles, users } from '@hearmecheer/shared/models';

const logger = debug('AuthProvider');

const AuthContext = React.createContext({});
export const useAuthContext = () => React.useContext(AuthContext);

const FIREBASE_MAX_QUERY_IN_LIMIT = 10;

const Roles = {
    dev: 100_000,
    super: 10_000,
    owner: 1_000,
    admin: 500,
    moderator: 100,
};

const makeContext = () => ({
    authUser: {},
    authUserLoaded: false,
    isAuthed: false,
    user: {},
    userLoaded: false,
    profileCompanies: [],
    profileCompaniesLoaded: false,
    accessCompanies: [],
    accessCompaniesLoaded: false,
    company: {},
    companyLoaded: false,
    hasCompany: false,
    profile: {},
    profileLoaded: false,
    contextReady: false,
});

const contextReducer = (state, action) => {
    logger('Dispatching action', action);
    switch (action.type) {
        case 'AUTH_USER': {
            return {
                ...state,
                authUserLoaded: true,
                authUser: action.item,
                isAuthed: true,
            };
        }
        case 'USER': {
            return { ...state, userLoaded: true, user: action.item, profileCompanies: [] };
        }
        case 'PROFILE_COMPANIES':
            return {
                ...state,
                profileCompaniesLoaded: true,
                profileCompanies: [...state.profileCompanies, ...action.list],
            };
        case 'ACCESS_COMPANIES':
            return {
                ...state,
                accessCompaniesLoaded: true,
                accessCompanies: action.list || [],
            };
        case 'COMPANY': {
            return {
                ...state,
                companyLoaded: true,
                company: action.item,
                hasCompany: true,
            };
        }
        case 'PROFILE':
            return {
                ...state,
                profileLoaded: true,
                profile: action.item,
            };
        case 'CONTEXT_READY':
            return {
                ...state,
                contextReady: true,
            };
        case 'RESET':
            return makeContext();
        default:
            console.warn('Unhandled action type ' + action.type);
            return state;
    }
};

const AuthContextProvider = (props) => {
    const { children } = props;
    const [store, dispatchStore] = useReducer(contextReducer, null, makeContext);

    const {
        authUser,
        authUserLoaded,
        isAuthed,
        user,
        userLoaded,
        profileCompanies,
        profileCompaniesLoaded,
        accessCompanies,
        accessCompaniesLoaded,
        company,
        companyLoaded,
        profile,
        contextReady,
    } = store;

    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (user) {
                dispatchStore({ type: 'AUTH_USER', item: user });
            }
        });
    }, []);

    useEffect(() => {
        if (!authUserLoaded) {
            const timerId = setTimeout(() => {
                logger("Firebase didn't respond. Assuming user is unauthenticated");
                dispatchStore({ type: 'CONTEXT_READY' });
            }, 2000);
            return () => clearTimeout(timerId);
        }
    }, [authUserLoaded]);

    useEffect(() => {
        if (!isAuthed) {
            return;
        }
        return users.getSnapshot(users.makeRef(authUser.uid), (user) => dispatchStore({ type: 'USER', item: user }));
    }, [authUser, isAuthed]);

    useEffect(() => {
        if (!userLoaded) {
            return;
        }

        // handle users without a companyID
        if (!user.companyIds || user.companyIds.length === 0) {
            if (user.isSuper || user.isDev) {
                return dispatchStore({ type: 'PROFILE_COMPANIES', list: [] });
            }
            return dispatchStore({ type: 'CONTEXT_READY' });
        }

        const numberOfSnapshots = user.companyIds.length / FIREBASE_MAX_QUERY_IN_LIMIT;
        const snapshotSubscriptions = [];

        const handleSnapshot = (list) => dispatchStore({ type: 'PROFILE_COMPANIES', list });

        for (let i = 0; i < numberOfSnapshots; i++) {
            const queryStartingIndex = i * FIREBASE_MAX_QUERY_IN_LIMIT;
            const queryEndingIndex = queryStartingIndex + FIREBASE_MAX_QUERY_IN_LIMIT;
            const queryRange = user.companyIds.slice(queryStartingIndex, queryEndingIndex);

            snapshotSubscriptions.push(
                properties.listSnapshot(
                    properties.makeRef().where(firebase.firestore.FieldPath.documentId(), 'in', queryRange),
                    handleSnapshot,
                ),
            );
        }

        return () => {
            snapshotSubscriptions.forEach((unsubscribe) => unsubscribe());
        };
    }, [user.companyIds, user.isDev, user.isSuper, userLoaded]);

    useEffect(() => {
        if (!profileCompaniesLoaded) {
            return;
        }
        if (!(user.isDev || user.isSuper)) {
            return dispatchStore({
                type: 'ACCESS_COMPANIES',
                list: profileCompanies,
            });
        }
        return properties.listSnapshot(properties.makeRef(), (accessCompanies) =>
            dispatchStore({
                type: 'ACCESS_COMPANIES',
                list: accessCompanies,
            }),
        );
    }, [profileCompanies, profileCompaniesLoaded, user.isDev, user.isSuper]);

    useEffect(() => {
        if (!accessCompaniesLoaded) {
            return;
        }
        if (!accessCompanies.length) {
            return dispatchStore({ type: 'CONTEXT_READY' });
        }

        try {
            const storedId = window.sessionStorage.getItem('hmc.propertyId');
            if (storedId !== null) {
                logger('Found ID from session storage: ', storedId);
                const company = accessCompanies.find((company) => company.id === storedId);
                if (company) {
                    return dispatchStore({ type: 'COMPANY', item: company });
                }
            }
        } catch (err) {}

        logger('Using first company on the stack');
        dispatchStore({ type: 'COMPANY', item: accessCompanies[0] });
    }, [accessCompanies, accessCompaniesLoaded]);

    useEffect(() => {
        if (!companyLoaded || !company.id) {
            return;
        }

        return profiles.getSnapshot(profiles.makeRef(company.id, user.email), (profile) => {
            if (profile) {
                dispatchStore({ type: 'PROFILE', item: profile });
            }
            dispatchStore({ type: 'CONTEXT_READY' });
        });
    }, [company.id, companyLoaded, user.email]);

    const userRoleScore = useMemo(() => {
        if (user.isDev) {
            return Roles.dev;
        }
        if (user.isSuper) {
            return Roles.super;
        }
        return Roles[profile.role] || 0;
    }, [profile.role, user.isDev, user.isSuper]);

    const hasRole = useCallback(
        (requiredRole) => {
            return userRoleScore >= Roles[requiredRole];
        },
        [userRoleScore],
    );

    const changeCompany = useCallback(
        (companyId) => {
            const company = accessCompanies.find((company) => company.id === companyId);
            if (company) {
                dispatchStore({ type: 'COMPANY', item: company });
            }
        },
        [accessCompanies],
    );

    const signOut = useCallback(async () => {
        await auth.signOut();
        dispatchStore({ type: 'RESET' });
    }, []);

    if (!contextReady) {
        return <LoadingBackdrop />;
    }

    const context = { ...store, changeCompany, hasRole, signOut };
    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
    children: PropTypes.node,
};
AuthContextProvider.defaultProps = {};

export default AuthContextProvider;
