import React, { useCallback, useState } from 'react';
import PageLayout from '../../core/layouts/PageLayout';
import PasswordField from '../../core/inputs/PasswordField';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import { auth } from '@hearmecheer/shared/firebase/client';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import SaveButton from '../../admin/components/SaveButton';
import Button from '../../core/buttons/Button';
import { useHistory } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

const ChangePassword = (props) => {
    const { goBack } = useHistory();
    const { emitError } = useSnackbar();
    const [waiting, setWaiting] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const updatePassword = useCallback(async () => {
        setWaiting(true);
        if (password !== confirmPassword) {
            emitError('Passwords do not match!');
            setWaiting(false);
            return;
        }

        try {
            await auth.currentUser.updatePassword(password);
            goBack();
        } catch (err) {
            emitError('We were unable to update your password at this time. Please try again at a later time');
        }
        setWaiting(false);
    }, [confirmPassword, emitError, goBack, password]);

    const handlePasswordChange = useCallback((e) => {
        const { value } = e.target;
        setPassword(value);
    }, []);

    const handleConfirmPasswordChange = useCallback((e) => {
        const { value } = e.target;
        setConfirmPassword(value);
    }, []);

    return (
        <PageLayout title={'Change Your Password'}>
            <LoadingBackdrop open={waiting} />
            <Stack spacing={4}>
                <PasswordField value={password} onChange={handlePasswordChange} />
                <PasswordField
                    label={'Confirm Password'}
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                />

                <Box textAlign={'right'}>
                    <Button flat onClick={goBack}>
                        Cancel
                    </Button>
                    <SaveButton isCreating onSave={updatePassword}>
                        Save
                    </SaveButton>
                </Box>
            </Stack>
        </PageLayout>
    );
};

ChangePassword.propTypes = {};
ChangePassword.defaultProps = {};

export default ChangePassword;
