import { functions } from '@hearmecheer/shared/firebase/client';
import { rooms, tracks } from '@hearmecheer/shared/models';
import {
    Delete as DeleteIcon,
    MoreVert as MoreVertIcon,
    PlayArrow as PlayArrowIcon,
    Stop as StopIcon,
} from '@mui/icons-material';
import {
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import debug from 'debug';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import Button from '../../core/buttons/Button';
import TextField from '../../core/inputs/TextField';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import { useGameContext } from '../providers/GameContext';
import Editable from './Editable';

const logger = debug('BackgroundAudioDialog');

const createTrack = functions.httpsCallable('tracks_create');
const playTrack = functions.httpsCallable('tracks_play');

const makeAudioTrack = (schema) => ({
    ...rooms.fields.find((field) => field.name === 'audioTracks').fieldType.getValue(schema),
    playStartTime: null,
});

const RowItem = ({ roomId, track, roomTrackList }) => {
    const { company } = useAuthContext();
    const { game } = useGameContext();
    const { emitSuccess } = useSnackbar();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const roomTrack = roomTrackList.find(({ trackId }) => track.id === trackId) || {};
    logger('roomTrack', roomTrack);

    const openMenu = useCallback(({ currentTarget }) => {
        setMenuAnchorEl(currentTarget);
    }, []);

    const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

    const handleDelete = useCallback(async () => {
        await tracks.makeRef(company.id, game.id, track.id).delete();
        emitSuccess('Track deleted');
    }, [company.id, game.id, track.id, emitSuccess]);

    const updateTrackTitle = useCallback(
        async (updatedTitle) => {
            await tracks.update(tracks.makeRef(company.id, game.id, track.id), { title: updatedTitle });
            emitSuccess('Track title updated successfully');
        },
        [company.id, game.id, track.id, emitSuccess],
    );

    const handleLoopChange = useCallback(
        async ({ currentTarget: { checked } }) => {
            const updatedList = !roomTrack.trackId
                ? [...roomTrackList, makeAudioTrack({ trackId: track.id, isLooping: checked })]
                : roomTrackList.map((track) =>
                      makeAudioTrack(track.trackId !== roomTrack.trackId ? track : { ...track, isLooping: checked }),
                  );
            logger('handleLoopChange', { checked, trackId: roomTrack.trackId, roomTrackList, updatedList });
            await rooms.update(rooms.makeRef(company.id, game.id, roomId), { audioTracks: updatedList });
        },
        [company.id, game.id, track.id, roomTrack.trackId, roomTrackList, roomId],
    );

    const handlePlayTrack = useCallback(
        () => playTrack({ propertyId: company.id, eventId: game.id, roomId, trackId: track.id }),
        [company.id, game.id, roomId, track.id],
    );

    const handleStopTrack = useCallback(async () => {
        const updatedList = !roomTrack.trackId
            ? [...roomTrackList, makeAudioTrack({ trackId: track.id, playStartTime: null })]
            : roomTrackList.map((track) =>
                  makeAudioTrack(track.trackId !== roomTrack.trackId ? track : { ...track, playStartTime: null }),
              );
        await rooms.update(rooms.makeRef(company.id, game.id, roomId), { audioTracks: updatedList });
    }, [company.id, game.id, roomId, roomTrack.trackId, roomTrackList, track.id]);

    return (
        <TableRow key={track.id}>
            <TableCell>
                <Editable value={track.title} onSubmit={updateTrackTitle} multiline>
                    {track.title}
                </Editable>
            </TableCell>
            <TableCell>
                <Checkbox checked={roomTrack.isLooping ?? false} onChange={handleLoopChange} />
            </TableCell>
            <TableCell>
                <Stack direction="row">
                    {!roomTrack.playStartTime ? (
                        <IconButton onClick={handlePlayTrack}>
                            <PlayArrowIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={handleStopTrack}>
                            <StopIcon />
                        </IconButton>
                    )}
                    <IconButton edge="end" onClick={openMenu}>
                        <MoreVertIcon />
                    </IconButton>
                </Stack>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClick={closeMenu}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MenuItem onClick={handleDelete}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <ListItemText primary="Remove from Library" />
                    </MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
    );
};

const BackgroundAudioDialog = ({ open, onClose, room, onSave }) => {
    const { company } = useAuthContext();
    const { game, trackList } = useGameContext();
    const { emitSuccess, emitError } = useSnackbar();
    const [newTrackUrl, setNewTrackUrl] = useState('');

    const addTrackToLibrary = useCallback(async () => {
        try {
            const response = await createTrack({
                context: { propertyId: company.id, eventId: game.id },
                body: { url: newTrackUrl },
            });
            const { status, message } = response.data;
            if (status === 'error') {
                emitError(message);
            } else {
                emitSuccess('Added new track successfully');
                setNewTrackUrl('');
            }
        } catch (err) {
            console.error(err);
            emitError('Error occurred. Please try again later');
        }
    }, [company.id, game.id, newTrackUrl, emitError, emitSuccess]);

    const handleNewTrackUrlChange = useCallback((e) => {
        setNewTrackUrl(e.currentTarget.value);
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Play a sound to &quot;{room.name}&quot; room</DialogTitle>
            <DialogContent>
                <Stack spacing={2} divider={<Divider flexItem />}>
                    <div>
                        <Typography variant="h6">Library</Typography>
                        {trackList.length ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Loop</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trackList.map((track) => (
                                        <RowItem
                                            key={track.id}
                                            roomId={room.id}
                                            track={track}
                                            roomTrackList={room.audioTracks}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        ) : (
                            <Typography variant={'body2'} color={'textSecondary'}>
                                <em>Empty</em>
                            </Typography>
                        )}
                    </div>

                    <Stack>
                        <Typography variant="overline">Add new track to Library</Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <TextField
                                value={newTrackUrl}
                                onChange={handleNewTrackUrlChange}
                                fullWidth
                                placeholder="https://someurl.com/path/to/file.mp3"
                            />
                            <Button size="small" onClick={addTrackToLibrary}>
                                Add
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

BackgroundAudioDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    room: PropTypes.object,
    onSave: PropTypes.func,
};
BackgroundAudioDialog.defaultProps = {};

export default BackgroundAudioDialog;
