import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SimpleDialog from '../../core/dialogs/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import EmbedCode from '../../core/components/EmbedCode';

function EmbedCodeDialog(props) {
    const { propertyId, eventId, onClose } = props;
    const { emitSuccess } = useSnackbar();
    const { t } = useTranslation();

    const [htmlCode, setHtmlCode] = useState('');

    const handleEmbedCode = useCallback((code) => {
        setHtmlCode(code);
    }, []);

    const showCopySuccessMessage = useCallback(() => {
        emitSuccess('HTML code copied successfully to your clipboard');
    }, [emitSuccess]);

    return (
        <SimpleDialog
            open={propertyId && eventId}
            onClose={onClose}
            title={t('HTML code')}
            body={
                <Stack spacing={2}>
                    <Typography variant="body1">{t('Insert the following HTML code into your site')}</Typography>
                    <EmbedCode propertyId={propertyId} eventId={eventId} onCode={handleEmbedCode} />
                    <TextField value={htmlCode} variant="filled" multiline />
                </Stack>
            }
            actions={[
                <Button key="close" flat onClick={onClose}>
                    {t('Close')}
                </Button>,
                <CopyToClipboard key="copy" text={htmlCode} onCopy={showCopySuccessMessage}>
                    <Button flat color="primary">
                        {t('Copy')}
                    </Button>
                </CopyToClipboard>,
            ]}
        />
    );
}

EmbedCodeDialog.propTypes = {
    propertyId: PropTypes.string,
    eventId: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
EmbedCodeDialog.defaultProps = {};

export default EmbedCodeDialog;
