import i18next from 'i18next';
import { es, ja, nl, pl } from 'date-fns/locale';

const languages = [
    { locale: 'en', date: null, displayName: 'English' },
    // {locale: "cat", displayName: "Cat"},
    { locale: 'es', date: es, displayName: 'Español' },
    // {locale: "de", displayName: "German"},
    { locale: 'pl', date: pl, displayName: 'Polish' },
    { locale: 'nl', date: nl, displayName: 'Nederlands' },
    { locale: 'ja', date: ja, displayName: '日本人' },
];

export function currentLanguage() {
    const fullLocale = i18next.language;

    // remove anything after a hyphen if it's there
    // eg. en-CA => en
    const [locale] = fullLocale.split('-');
    return languages.find((lang) => lang.locale === locale);
}

/**
 * returns an dict without the second dicts keys
 * intended for use on the browser to filter out language
 * keys that have already been translated
 *
 * eg. filterDuplicates(en, ja) -> untranslated values
 */
// function filterDuplicates(dict, compareDict) {
//     return Object.entries(dict).reduce((d, [key, value]) => {
//         if (!(key in compareDict)) {
//             d[key] = value;
//         }
//         return d;
//     }, {});
// }

export default languages;
