import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        htmlFontSize: 16,
        h1: {
            fontSize: '2.2rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        h2: {
            fontSize: '2rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        h3: {
            fontSize: '1.6rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        h4: {
            fontSize: '1.4rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        h5: {
            fontSize: '1rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        h6: {
            fontSize: '.9rem',
            textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        // overline: {
        //     fontSize: '.9rem',
        //     textTransform: 'uppercase',
        //     letterSpacing: '.09em',
        // },
        // caption: {
        //     fontSize: '.9rem',
        //     textTransform: 'uppercase',
        //     letterSpacing: '.090em',
        // },
        button: {
            // fontSize: '.9rem',
            // fontWeight: 'bold',
            // textTransform: 'uppercase',
            letterSpacing: '.08em',
        },
        // body1: {
        //     fontSize: '1rem',
        //     letterSpacing: '.070em',
        // },
        // body2: {
        //     fontSize: '.9rem',
        //     letterSpacing: '.070em',
        // },
    },
    palette: {
        primary: {
            main: '#E32C6D',
        },
        secondary: {
            main: '#7E4072',
        },
        text: {
            primary: '#707070',
            secondary: '#aaa',
        },
    },
    shape: {
        borderRadius: 6,
    },
});

function ThemeProvider(props) {
    const { children } = props;
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
ThemeProvider.defaultProps = {};

export default ThemeProvider;
