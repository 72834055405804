import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import GoogleSignOnButton from './buttons/GoogleSignOnButton';
import FacebookSignOnButton from './buttons/FacebookSignOnButton';
import GithubSignOnButton from './buttons/GithubSignOnButton';

function SingleSignOnProviders(props) {
    const { inOrUp } = props;
    if (process.env.NODE_ENV !== 'development') {
        return null;
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <GoogleSignOnButton inOrUp={inOrUp} />
            </Grid>
            <Grid item xs={12}>
                <FacebookSignOnButton inOrUp={inOrUp} />
            </Grid>
            <Grid item xs={12}>
                <GithubSignOnButton inOrUp={inOrUp} />
            </Grid>
        </Grid>
    );
}

SingleSignOnProviders.propTypes = {
    inOrUp: PropTypes.oneOf(['in', 'up']).isRequired,
};
SingleSignOnProviders.defaultProps = {};

export default SingleSignOnProviders;
