import React from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const flatProps = (flat) => (flat ? null : { variant: 'contained' });

const UnstyledButton = ({ flat, ...buttonProps }) => {
    return <MuiButton {...flatProps(flat)} {...buttonProps} />;
};

const Button = styled(UnstyledButton, {
    shouldForwardProp: (prop) => !['fullWidth', 'size'].includes(prop),
})(({ theme, size, fullWidth }) => ({
    padding: theme.spacing(size === 'small' ? 1 : 2, size === 'small' ? 2 : size === 'medium' ? 3 : 4),
    ...(fullWidth && { width: '100%' }),
}));

Button.propTypes = {
    fullWidth: PropTypes.bool,
};
Button.defaultProps = {
    size: 'medium',
    fullWidth: false,
};

export default Button;
