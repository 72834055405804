import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import TextField from '../../core/inputs/TextField';

const CancelButton = (props) => (
    <IconButton {...props}>
        <CloseIcon />
    </IconButton>
);

const UnstyledEditButton = (props) => (
    <IconButton {...props}>
        <EditIcon />
    </IconButton>
);
const EditButton = styled(UnstyledEditButton)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(0, -50%)',
    visibility: 'hidden',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
        background: theme.palette.primary.light,
    },
}));

const UnstyledSaveButton = (props) => (
    <IconButton {...props}>
        <CheckIcon />
    </IconButton>
);
const SaveButton = styled(UnstyledSaveButton)(({ theme }) => ({
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
        background: theme.palette.success.light,
    },
}));

const HoverOutline = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
    minHeight: theme.spacing(4.25),

    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: 'rgba(0,0,0,0)',
    borderRadius: 1000,

    '&:hover': {
        borderColor: theme.palette.primary.main,
        '& > button': {
            visibility: 'visible',
        },
    },
}));

const Editable = (props) => {
    const { value: propValue, children, onSubmit, ...textFieldProps } = props;
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const toggleEditMode = useCallback(() => {
        setEditMode((mode) => !mode);
    }, []);

    const handleValueChange = useCallback((e) => {
        const { value } = e.target;
        setValue(value);
    }, []);

    const handleSubmit = useCallback(() => {
        setEditMode(false);
        onSubmit(value);
    }, [onSubmit, value]);

    if (!editMode) {
        return (
            <HoverOutline>
                {children}
                <EditButton onClick={toggleEditMode} size={'small'} />
            </HoverOutline>
        );
    }

    return (
        <Box flex={1}>
            <TextField
                {...textFieldProps}
                autoFocus
                value={value}
                onChange={handleValueChange}
                onEnter={handleSubmit}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SaveButton onClick={handleSubmit} />
                            <CancelButton onClick={toggleEditMode} />
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

Editable.propTypes = {
    value: PropTypes.any,
    children: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
};
Editable.defaultProps = {};

export default Editable;
