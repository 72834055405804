import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const FixedActionButton = styled(IconButton)(({ theme }) => ({
    background: theme.palette.primary.main,
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    padding: theme.spacing(1.5),
    zIndex: 1,
    color: theme.palette.common.white,
    '&:hover': {
        background: theme.palette.error.light,
    },
}));

export default FixedActionButton;
