import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import HmcLogo from '../icons/HmcLogo.svg';
import { coreRoutes } from '../RoutesMap';
import { styled } from '@mui/material/styles';

const LogoImage = styled('img')(({ theme }) => ({
    width: '100%',
    maxWidth: theme.spacing(50),
}));

const EnvLogo = () => {
    if (process.env.REACT_APP_ENV === 'dscryb') {
        return (
            <LogoImage
                src={'https://dscryb.com/wp-content/uploads/2020/12/dscryb-logotype-final-black.png'}
                alt="dscryb logo"
            />
        );
    }
    return <LogoImage src={HmcLogo} alt={'HearMeCheer Logo'} />;
};

const Logo = () => (
    <RouterLink to={coreRoutes.home}>
        <EnvLogo />
    </RouterLink>
);

Logo.propTypes = {
    size: PropTypes.oneOf(['small', 'large']).isRequired,
};
Logo.defaultProps = {
    size: 'large',
};

export default Logo;
