import React, { useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { useHistory } from 'react-router-dom';
import { authRoutes } from '../RoutesMap';

const CustomInput = (props) => <TextField {...props} variant="standard" label="Current Property" />;

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: 1000,
    color: theme.palette.common.white,
    padding: theme.spacing(0, 2),
    width: 300,
    '& .MuiInput-root': {
        color: theme.palette.text.secondary,
    },
    '& .MuiAutocomplete-endAdornment': {
        top: theme.spacing(-0.5),
    },
    '& .MuiIconButton-root': {
        color: theme.palette.text.secondary,
    },
}));

const propertySort = (a, b) => a.name.localeCompare(b.name);

const PropertyAutocomplete = (props) => {
    const { accessCompanies, company } = useAuthContext();
    const { push } = useHistory();

    const handleChange = useCallback(
        (e, next) => {
            if (next && next.id) {
                push(authRoutes.changeProperty.replace(':propertyId', next.id));
            }
        },
        [push],
    );

    const isOptionEqualToValue = useCallback((option, value) => option.name === value, []);

    if (!accessCompanies.length) {
        return null;
    }

    return (
        <StyledAutocomplete
            disablePortal
            selectOnFocus
            clearOnEscape
            autoHighlight
            handleHomeEndKeys
            options={[...accessCompanies].sort(propertySort)}
            getOptionLabel={(option) => option.name}
            value={company}
            onChange={handleChange}
            renderInput={(props) => <CustomInput {...props} />}
            isOptionEqualToValue={isOptionEqualToValue}
            {...props}
        />
    );
};

export default PropertyAutocomplete;
