import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography } from '@mui/material';
import Logo from '../../core/components/Logo';

function PageLayout(props) {
    const { pageTitle, children, aside } = props;
    return (
        <Container>
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <Box mt={8}>
                        <Logo />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2">{pageTitle}</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    {children}
                </Grid>
                <Grid item md={6} xs={12}>
                    {aside}
                </Grid>
            </Grid>
        </Container>
    );
}

PageLayout.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    children: PropTypes.node,
    aside: PropTypes.node,
};
PageLayout.defaultProps = {};

export default PageLayout;
