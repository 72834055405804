import React from 'react';
import { makeStyles } from '@mui/styles';
import { PanToolOutlined as PanToolIcon } from '@mui/icons-material';

const useStyles = makeStyles({
    icon: {
        marginRight: 2,
    },
});

function RaisedHandIcon() {
    const classes = useStyles();
    return <PanToolIcon className={classes.icon} />;
}

RaisedHandIcon.propTypes = {};
RaisedHandIcon.defaultProps = {};

export default RaisedHandIcon;
