import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

function ExpandMenuButton(props) {
    const { id, onClick } = props;
    return (
        <IconButton onClick={onClick} data-id={id}>
            <MoreVertIcon />
        </IconButton>
    );
}

ExpandMenuButton.propTypes = {
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
ExpandMenuButton.defaultProps = {};

export default ExpandMenuButton;
