import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

function SectionTitle(props) {
    const { title, subtitle, gutter } = props;

    if (!title && !subtitle) {
        return null;
    }

    return (
        <Box mb={4} mt={gutter ? 8 : 0}>
            {title && (
                <Box mb={1}>
                    <Typography variant="h3" color="textPrimary" component="div">
                        {title}
                    </Typography>
                </Box>
            )}

            {subtitle && (
                <Typography variant="body2" color="textSecondary" component="div">
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
}

SectionTitle.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    gutter: PropTypes.bool,
};
SectionTitle.defaultProps = {
    gutter: true,
};

export default SectionTitle;
