import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Button from '../../core/buttons/Button';
import CopyTextField from './CopyTextField';
import { useTranslation } from 'react-i18next';

function ApiKeyField(props) {
    const { label, value, field, gutter, onRegenerate } = props;
    const { t } = useTranslation();
    return (
        <div>
            <Box display="flex">
                <CopyTextField label={label} value={value} gutter={gutter} fullWidth={false} />
                <Box ml={10}>
                    <Button variant="outlined" size="small" onClick={onRegenerate} data-field={field}>
                        {t('Regenerate')}
                    </Button>
                </Box>
            </Box>
        </div>
    );
}

ApiKeyField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    gutter: PropTypes.any,
    field: PropTypes.string.isRequired,
    onRegenerate: PropTypes.func.isRequired,
};
ApiKeyField.defaultProps = {};

export default ApiKeyField;
