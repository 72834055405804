import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import debug from 'debug';
import { Link as RouterLink } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import AddIcon from '@mui/icons-material/Add';
import PageLayout from '../../core/layouts/PageLayout';
import FixedActionButton from '../../core/buttons/FixedActionButton';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { List, Paper } from '@mui/material';

const logger = debug('ListView.js');

function ListView(props) {
    const {
        title,
        subtitle,
        createUrl,
        onCreate,
        modelRef,
        menuOptions,
        children,
        renderModelItem,
        renderEmptyList,
        onSelectedItemChange,
        useListCards,
    } = props;
    const [modelItemList, setModelItemList] = useState(null);
    const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);

    useEffect(() => {
        logger('Detected modelRef change');
        return modelRef.onSnapshot((snapshot) => {
            logger('modelRef snapshot');
            setModelItemList(snapshot.docs.map((d) => d.data()));
        });
    }, [modelRef]);

    const openActionMenu = useCallback(
        (e) => {
            const { currentTarget } = e;
            setActionMenuAnchorEl(currentTarget);
            onSelectedItemChange(modelItemList.find((model) => model.id === currentTarget.dataset.id));
        },
        [modelItemList, onSelectedItemChange],
    );

    const closeActionMenu = useCallback(() => {
        setActionMenuAnchorEl(null);
        onSelectedItemChange({});
    }, [onSelectedItemChange]);

    const renderModelWithContext = useCallback(
        (model, index, list) => {
            const isLast = index === list.length - 1;
            const context = {
                shouldHaveMenu: Boolean(menuOptions.length),
                openActionMenu,
                isLast,
            };
            return renderModelItem(model, context);
        },
        [menuOptions.length, openActionMenu, renderModelItem],
    );

    if (modelItemList === null) {
        return <LoadingBackdrop />;
    }

    // if (!modelItemList.length) {
    //     return (
    //         <div>
    //             {renderEmptyList}
    //             {children}
    //         </div>
    //     );
    // }

    return (
        <PageLayout title={title} subtitle={subtitle}>
            {useListCards ? (
                modelItemList.map(renderModelWithContext)
            ) : !modelItemList.length ? null : (
                <Paper elevation={2}>
                    <List>{modelItemList.map(renderModelWithContext)}</List>
                </Paper>
            )}

            {!menuOptions.length ? null : (
                <Menu
                    anchorEl={actionMenuAnchorEl}
                    open={Boolean(actionMenuAnchorEl)}
                    onClose={closeActionMenu}
                    onClick={closeActionMenu}
                >
                    {menuOptions}
                </Menu>
            )}

            {createUrl ? (
                <FixedActionButton component={RouterLink} to={createUrl}>
                    <AddIcon />
                </FixedActionButton>
            ) : (
                <FixedActionButton onClick={onCreate}>
                    <AddIcon />
                </FixedActionButton>
            )}

            {modelItemList.length ? null : renderEmptyList}
            {children}
        </PageLayout>
    );
}

ListView.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.node,
    createUrl: PropTypes.string,
    onCreate: PropTypes.func,
    modelRef: PropTypes.object.isRequired,
    menuOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            onClick: PropTypes.func,
        }).isRequired,
    ),
    children: PropTypes.node,
    renderModelItem: PropTypes.func.isRequired,
    renderEmptyList: PropTypes.node,
    onSelectedItemChange: PropTypes.func,
    useListCards: PropTypes.bool,
};

const DefaultNoResults = () => <div>No Results</div>;
ListView.defaultProps = {
    menuOptions: [],
    renderEmptyList: <DefaultNoResults />,
    useListCards: true,
};

export default ListView;
