import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import Languages from './Languages';

const resources = Object.assign(
    ...Languages.map((l) => ({
        [l.locale]: { translations: require('./lang/' + l.locale).default },
    })),
);

const init = {
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
};

i18n.use(LanguageDetector).use(initReactI18next).init(init);

export default i18n;
