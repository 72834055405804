import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthContextProvider';
import { eventRoutes } from '../../core/RoutesMap';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';

const ChangeProperty = () => {
    const { propertyId } = useParams();
    const { changeCompany, company } = useAuthContext();

    useEffect(() => {
        changeCompany(propertyId);
        window.sessionStorage.setItem('hmc.propertyId', propertyId);
    }, [changeCompany, propertyId]);

    if (company.id === propertyId) {
        return <Redirect to={eventRoutes.list} />;
    }

    return <LoadingBackdrop />;
};

ChangeProperty.propTypes = {};
ChangeProperty.defaultProps = {};

export default ChangeProperty;
