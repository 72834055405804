import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../forms/UserForm';
import { profiles } from '@hearmecheer/shared/models';
import FormContext from '../../core/providers/FormContext';
import PageLayout from '../../core/layouts/PageLayout';
import FormActions from '../layout/FormActions';
import { profileRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function UserForm() {
    const { company } = useAuthContext();
    const history = useHistory();
    const { userId } = useParams();

    const modelContext = useMemo(
        () => ({
            propertyId: company.id,
        }),
        [company.id],
    );

    const redirectToUserList = useCallback(() => {
        history.push(profileRoutes.list);
    }, [history]);

    const sanitize = useCallback((item) => {
        return {
            ...item,
            email: item.email.trim().toLowerCase(),
        };
    }, []);

    return (
        <FormContext
            model={profiles}
            modelId={userId}
            modelContext={modelContext}
            beforeSave={sanitize}
            onSubmit={redirectToUserList}
            onCancel={redirectToUserList}
        >
            <PageLayout title={'Users'}>
                <Form />
                <FormActions />
            </PageLayout>
        </FormContext>
    );
}

UserForm.propTypes = {};
UserForm.defaultProps = {};

export default UserForm;
