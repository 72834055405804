import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { auth } from '@hearmecheer/shared/firebase/client';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import useQueryParams from '../../core/hooks/useQueryParams';

const Token = () => {
    const history = useHistory();
    const { token } = useParams();
    const redirectPath = useQueryParams('redirectPath');
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const signInAndRedirect = useCallback(async (token) => {
        await auth.signInWithCustomToken(token);
        setShouldRedirect(true);
    }, []);

    useEffect(() => {
        if (token) {
            signInAndRedirect(token);
        }
    }, [token, signInAndRedirect]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setShouldRedirect(true);
        }, 5000);
        return () => clearTimeout(timerId);
    }, [history]);

    if (shouldRedirect) {
        return <Redirect replace to={redirectPath || '/'} />;
    }
    return <LoadingBackdrop />;
};

Token.propTypes = {};
Token.defaultProps = {};

export default Token;
