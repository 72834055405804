import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import GithubIcon from '../icons/GithubIcon';
import Button from '../../../core/buttons/Button';
import { useSnackbar } from '../../../core/providers/SnackbarProvider';

const githubBrandGrey = '#24292E';
const useStyles = makeStyles((theme) => ({
    root: {
        background: githubBrandGrey,
        color: theme.palette.common.white,
        '&:hover': {
            background: alpha(githubBrandGrey, 0.7),
        },
    },
}));

function GithubSignOnButton(props) {
    const { inOrUp } = props;
    const classes = useStyles();
    const { emitSuccess } = useSnackbar();
    const { t } = useTranslation();

    const handleSignIn = useCallback(() => {
        emitSuccess('Hello');
    }, [emitSuccess]);

    return (
        <Button className={classes.root} size="large" fullWidth startIcon={<GithubIcon />} onClick={handleSignIn}>
            {t('Sign-{{inOrUp}} With Github', { inOrUp })}
        </Button>
    );
}

GithubSignOnButton.propTypes = {
    inOrUp: PropTypes.oneOf(['in', 'up']).isRequired,
};
GithubSignOnButton.defaultProps = {};

export default GithubSignOnButton;
