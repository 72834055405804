import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const EmbedCode = (props) => {
    const { propertyId, eventId, onCode } = props;
    const [code, setCode] = useState('');
    const previewRef = useRef();

    useEffect(() => {
        if (code) {
            previewRef.current.innerHTML = code;
            if (onCode) {
                onCode(code);
            }
        }
    }, [code, onCode]);

    useEffect(() => {
        if (propertyId && eventId) {
            const iframeUrl = new URL(`/${propertyId}/${eventId}`, process.env.REACT_APP_EMBED_SERVER);
            const jsUrl = new URL(`/assets/${propertyId}/${eventId}/init.js`, process.env.REACT_APP_EMBED_SERVER);

            let html = `<iframe title="HearMeCheer" src="${iframeUrl.href}" width="400" height="125" frameborder="0" allow="microphone" id='hmc-app'></iframe>`;
            html += `\n<script src="${jsUrl.href}"></script>`;
            setCode(html);
        }
    }, [propertyId, eventId]);

    return <div ref={previewRef} />;
};

EmbedCode.propTypes = {
    propertyId: PropTypes.string,
    eventId: PropTypes.string,
    onCode: PropTypes.func,
};
EmbedCode.defaultProps = {};

export default EmbedCode;
