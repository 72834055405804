import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { auth } from '@hearmecheer/shared/firebase/client';
import Button from '../../core/buttons/Button';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import PageLayout from '../layout/PageLayout';
import { useAuthContext } from '../providers/AuthContextProvider';

function VerifyEmail() {
    const { emitError } = useSnackbar();
    const { user } = useAuthContext();
    const [blurredEmail, setBlurredEmail] = useState('');

    useEffect(() => {
        const email = user.email;
        if (email) {
            const atIndex = email.indexOf('@');
            const emailPrefix = email.substring(0, atIndex);
            const emailSuffix = email.substring(atIndex);

            const blur = '*******';
            setBlurredEmail(`${email[0]}${email[1]}${blur}${emailPrefix[emailPrefix.length - 1]}${emailSuffix}`);
        }
    }, [user.email]);

    const sendVerificationEmail = useCallback(() => {
        const { protocol, host } = window.location;
        auth.currentUser
            .sendEmailVerification({
                // (optional) url to redirect back to
                url: `${protocol}//${host}`,
            })
            .catch(() => emitError('We were unable to send an email at this time. Please try again shortly'));
    }, [emitError]);

    useEffect(() => {
        sendVerificationEmail();
    }, [emitError, sendVerificationEmail]);

    return (
        <PageLayout pageTitle={'Verify Your Email Address'}>
            <Typography variant={'body1'}>
                {'We’ve sent you a verification email'}
                <strong>{blurredEmail}</strong>
            </Typography>
            <Box mt={8}>
                <Typography variant={'body2'}>{"Don't see an email in your inbox?"}</Typography>
                <Button onClick={sendVerificationEmail}>{'Resend Verification Email'}</Button>
            </Box>
        </PageLayout>
    );
}

VerifyEmail.propTypes = {};
VerifyEmail.defaultProps = {};

export default VerifyEmail;
