import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const AppContext = React.createContext({});
export const useAppContext = () => React.useContext(AppContext);

function AppContextProvider(props) {
    const { location } = useHistory();
    const [pageTitle, setPageTitle] = useState('');
    const [pageDescription, setPageDescription] = useState('');

    useEffect(() => {
        if (location.href) {
            setPageTitle('');
            setPageDescription('');
        }
    }, [location.href]);

    const context = {
        pageTitle,
        setPageTitle,
        pageDescription,
        setPageDescription,
    };
    return <AppContext.Provider value={context} {...props} />;
}

AppContextProvider.propTypes = {
    href: PropTypes.string,
};
AppContextProvider.defaultProps = {};

export default AppContextProvider;
