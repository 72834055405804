import React from 'react';
import debug from 'debug';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Typography } from '@mui/material';
import { accountRoutes, authRoutes, coreRoutes, eventRoutes } from '../RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

const logger = debug('Handle404.js');

/**
 * Routes don't exist if the user isn't signed in or doesn't have permission
 * triggering a 404 error here where we should redirect them to where they should
 * be if we know, otherwise show a 404 error message
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function Handle404(props) {
    const { isAuthed, authUser, company } = useAuthContext();
    const match = useRouteMatch();

    logger(`Path ${match.path} returned 404`);
    if (!isAuthed) {
        logger('User not signed in. Redirecting...');
        return <Redirect to={authRoutes.signIn} />;
    }
    if (!authUser.emailVerified) {
        logger("User's email not yet verified. Redirecting...");
        return <Redirect to={authRoutes.verifyEmail} />;
    }
    if (!company.id) {
        logger("User doesn't have a company yet. Redirecting...");
        return <Redirect to={accountRoutes.create} />;
    }

    return (
        <Switch>
            <Redirect exact from={coreRoutes.home} to={eventRoutes.list} />
            <Redirect from={authRoutes.home} to={eventRoutes.list} />
            <Route path={'*'}>
                <Typography variant={'h1'}>404 Page Not Found</Typography>
            </Route>
        </Switch>
    );
}

Handle404.propTypes = {};
Handle404.defaultProps = {};

export default Handle404;
