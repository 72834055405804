import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../../admin/providers/AppContextProvider';

const PageLayout = (props) => {
    const { title, subtitle, children } = props;
    const { setPageTitle, setPageDescription } = useAppContext();

    useEffect(() => {
        setPageTitle(title);
        setPageDescription(subtitle);
        return () => {
            setPageTitle('');
            setPageDescription('');
        };
    }, [setPageDescription, setPageTitle, subtitle, title]);

    return children;
};

PageLayout.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node,
};
PageLayout.defaultProps = {};

export default PageLayout;
