import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGameContext } from '../providers/GameContext';
import CreateRoom from '../components/CreateRoom';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import HmcEmbed from '../../core/components/HmcEmbed';
import { events } from '@hearmecheer/shared/models';
import PluralLabel from '../components/PluralLabel';
import { Code as CodeIcon, MoreVert as MoreVertIcon, Title as TitleIcon } from '@mui/icons-material';
import Editable from '../components/Editable';
import GameSearch from '../components/GameSearch';
import RoomGrid from '../layout/RoomGrid';
import CustomCodeDialog from '../components/CustomCodeDialog';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';

const TaglineDialog = ({ onClose, ...dialogProps }) => {
    const { company } = useAuthContext();
    const { game } = useGameContext();
    const [tagline, setTagline] = useState('');

    useEffect(() => {
        if (game.tagline) {
            setTagline(game.tagline);
        }
    }, [game.tagline]);

    const handleTaglineChange = useCallback((e) => {
        setTagline(e.target.value);
    }, []);

    const handleSave = useCallback(async () => {
        await events.update(events.makeRef(company.id, game.id), { tagline });
        onClose();
    }, [company.id, game.id, tagline, onClose]);

    return (
        <Dialog {...dialogProps}>
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <TitleIcon />
                    <span>Tagline</span>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <TextField value={tagline} onChange={handleTaglineChange} />
            </DialogContent>
            <DialogActions>
                <Button flat onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};
TaglineDialog.propTypes = {
    onClose: PropTypes.func,
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    width: 300,
    alignItems: 'flex-start',
    '& > .MuiCheckbox-root': {
        marginTop: theme.spacing(-1),
        marginLeft: theme.spacing(-2),
    },
    '& .MuiListItemText-secondary': {
        whiteSpace: 'break-spaces',
    },
}));
const MenuItemCheckbox = ({ children, checked, onChange }) => (
    <StyledMenuItem onClick={onChange}>
        <Checkbox checked={checked} onChange={onChange} />
        {children}
    </StyledMenuItem>
);
MenuItemCheckbox.propTypes = {
    children: PropTypes.node,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

const HeaderAside = () => {
    const { company } = useAuthContext();
    const { game, participantList, primaryRoomList } = useGameContext();
    const [customCodeOpen, setCustomCodeOpen] = useState(false);
    const [taglineOpen, setTaglineOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    const handleRaiseHandChange = useCallback(() => {
        events.update(events.makeRef(company.id, game.id), {
            allowRaiseHand: !game.allowRaiseHand,
        });
    }, [company.id, game.allowRaiseHand, game.id]);

    const handleAnonymousUsersChange = useCallback(() => {
        events.update(events.makeRef(company.id, game.id), {
            allowAnonymousUsers: !game.allowAnonymousUsers,
        });
    }, [company.id, game.allowAnonymousUsers, game.id]);

    const showMenu = useCallback((e) => {
        setMenuAnchorEl(e.target);
    }, []);

    const hideMenu = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);

    const showCustomCode = useCallback(() => {
        setCustomCodeOpen(true);
    }, []);

    const hideCustomCode = useCallback(() => {
        setCustomCodeOpen(false);
    }, []);

    const showTaglineDialog = useCallback(() => {
        setTaglineOpen(true);
    }, []);

    const hideTaglineDialog = useCallback(() => {
        setTaglineOpen(false);
    }, []);

    return (
        <Stack spacing={3} direction="row">
            <Divider orientation={'vertical'} flexItem sx={{ display: { xs: 'none', md: 'block' } }} />
            <Stack spacing={2}>
                <Stack direction="row" spacing={4} justifyContent={'flex-end'}>
                    <Typography variant={'overline'}>
                        <PluralLabel number={participantList.length} singular={'participant'} />
                    </Typography>
                    <Typography variant={'overline'}>
                        <PluralLabel number={primaryRoomList.length} singular={'room'} />
                    </Typography>
                    <IconButton onClick={showMenu}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={hideMenu}>
                        <MenuItem onClick={showTaglineDialog}>
                            <ListItemIcon>
                                <TitleIcon />
                            </ListItemIcon>
                            <ListItemText>Change Tagline</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={showCustomCode}>
                            <ListItemIcon>
                                <CodeIcon />
                            </ListItemIcon>
                            <ListItemText>Custom Code</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItemCheckbox checked={game.allowRaiseHand} onChange={handleRaiseHandChange}>
                            <ListItemText
                                primary={'Allow Users to Raise Hands'}
                                secondary={'Page Reload Required for changes to take effect'}
                            />
                        </MenuItemCheckbox>
                        <MenuItemCheckbox checked={game.allowAnonymousUsers} onChange={handleAnonymousUsersChange}>
                            <ListItemText
                                primary="Allow Anonymous Users"
                                secondary="When off, users must be authed using SSO"
                            />
                        </MenuItemCheckbox>
                    </Menu>
                    <CustomCodeDialog open={customCodeOpen} onClose={hideCustomCode} />
                    <TaglineDialog open={taglineOpen} onClose={hideTaglineDialog} />
                </Stack>
                <GameSearch />
            </Stack>
        </Stack>
    );
};

const HeaderMain = () => {
    const { company } = useAuthContext();
    const { game } = useGameContext();

    const handleNameUpdate = useCallback(
        (name) => {
            events.update(events.makeRef(company.id, game.id), { name });
        },
        [company.id, game.id],
    );

    const handleDescriptionUpdate = useCallback(
        (description) => {
            events.update(events.makeRef(company.id, game.id), { description });
        },
        [company.id, game.id],
    );

    return (
        <Stack spacing={2}>
            <Editable value={game.name} onSubmit={handleNameUpdate}>
                <Typography variant={'h3'}>{game.name}</Typography>
            </Editable>
            <Editable value={game.description} onSubmit={handleDescriptionUpdate} multiline rows={4}>
                {game.description ? (
                    <Typography component={'pre'} variant={'body1'} color={'textSecondary'}>
                        {game.description}
                    </Typography>
                ) : (
                    <Typography variant={'body2'} color={'textSecondary'}>
                        <em>No Description Available</em>
                    </Typography>
                )}
            </Editable>
        </Stack>
    );
};

const Header = () => (
    <Stack
        spacing={4}
        direction={{ xs: 'column', md: 'row' }}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
    >
        <HeaderMain />
        <HeaderAside />
    </Stack>
);

function ModerateGame() {
    const { company } = useAuthContext();
    const { game } = useGameContext();
    return (
        <Stack spacing={4}>
            <CreateRoom />
            <Header />
            <Divider />
            <HmcEmbed propertyId={company.id} eventId={game.id} />
            <RoomGrid />
        </Stack>
    );
}

ModerateGame.propTypes = {};
ModerateGame.defaultProps = {};

export default ModerateGame;
