import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoutes from '../auth/Routes';
import AdminRoutes from '../admin/Routes';
import AutomodRoutes from '../automod/Routes';
import DevRoutes from '../dev/Routes';
import ModerateRoutes from '../moderate/Routes';

const Routes = () => (
    <Switch>
        {process.env.NODE_ENV === 'development' && <Route path={'/dev'} component={DevRoutes} />}
        <Route path={'/auth'} component={AuthRoutes} />
        <Route path={'/automods'} component={AutomodRoutes} />
        <Route path={'/moderate'} component={ModerateRoutes} />
        <Route component={AdminRoutes} />
    </Switch>
);

Routes.propTypes = {};
Routes.defaultProps = {};

export default Routes;
