import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Icon from './GithubIcon.png';

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
}));

function GithubIcon(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    return <img src={Icon} alt={t('Github Icon')} className={classes.root} />;
}

GithubIcon.propTypes = {};
GithubIcon.defaultProps = {};

export default GithubIcon;
