import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import RoomGridItem from './RoomGridItem';
import { useGameContext } from '../providers/GameContext';

const RoomGrid = () => {
    const { primaryRoomList, partyRoomList, participantList, participantListFiltered, isSearching, searchQuery } =
        useGameContext();

    const [gridItems, setGridItems] = useState([]);

    useEffect(() => {
        const nextItems = [];
        for (const room of [...primaryRoomList, ...(isSearching ? partyRoomList : [])]) {
            const list = (isSearching ? participantListFiltered : participantList).filter((p) =>
                [p.primaryRoom, p.partyRoom].includes(room.id),
            );
            if (list.length || !isSearching) {
                nextItems.push({ room, participantList: list });
            }
        }
        setGridItems(nextItems);
    }, [primaryRoomList, partyRoomList, isSearching, participantListFiltered, participantList]);

    const renderItem = useCallback(({ participantList, room }) => {
        return <RoomGridItem key={room.id} room={room} participantList={participantList} />;
    }, []);

    if (!gridItems.length) {
        return (
            <Stack spacing={2} sx={{ mt: 4 }}>
                <Typography variant="h1">{'No Results'}</Typography>
                <Typography variant="body1">{`Nothing came up for "${searchQuery}"`}</Typography>
            </Stack>
        );
    }

    return (
        <Grid container spacing={1}>
            {gridItems.map(renderItem)}
        </Grid>
    );
};

RoomGrid.propTypes = {};
RoomGrid.defaultProps = {};

export default RoomGrid;
