import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { Code as CodeIcon, Delete as DeleteIcon } from '@mui/icons-material';
import EmbedCodeDialog from '../dialogs/EmbedCodeDialog';
import ConfirmDialog from '../../core/dialogs/ConfirmDialog';
import { events } from '@hearmecheer/shared/models';
import { moderateRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function EventListItem(props) {
    const { model, context, ...listItemProps } = props;
    const { id, name } = model;
    const { isLast } = context;
    const { company } = useAuthContext();

    const [embedDialog, setEmbedDialog] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    const showEmbedDialog = useCallback(() => {
        setEmbedDialog(true);
    }, []);

    const hideEmbedDialog = useCallback(() => {
        setEmbedDialog(false);
    }, []);

    const showConfirmDialog = useCallback(() => {
        setConfirmDialog(true);
    }, []);

    const hideConfirmDialog = useCallback(() => {
        setConfirmDialog(false);
    }, []);

    const deleteEvent = useCallback(() => {
        return events.delete(events.makeRef(company.id, id)).then(hideConfirmDialog);
    }, [company.id, hideConfirmDialog, id]);

    return (
        <ListItem
            button
            component={RouterLink}
            to={moderateRoutes.home.replace(':eventId', id)}
            divider={!isLast}
            {...listItemProps}
        >
            <ListItemText primary={name} secondary={''} />
            <ListItemSecondaryAction>
                <IconButton onClick={showEmbedDialog}>
                    <CodeIcon />
                </IconButton>
                {embedDialog && <EmbedCodeDialog propertyId={company.id} eventId={id} onClose={hideEmbedDialog} />}
                <IconButton onClick={showConfirmDialog}>
                    <DeleteIcon />
                </IconButton>
                <ConfirmDialog
                    open={confirmDialog}
                    title={'Are you sure you want to delete this event?'}
                    body={'This action is permanent and cannot be undone.'}
                    onCancel={hideConfirmDialog}
                    onConfirm={deleteEvent}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
}

EventListItem.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        schedule: PropTypes.object,
        visibility: PropTypes.string,
    }).isRequired,
    context: PropTypes.shape({
        shouldHaveMenu: PropTypes.bool,
        openActionMenu: PropTypes.func,
        isLast: PropTypes.bool,
    }).isRequired,
};
EventListItem.defaultProps = {};

export default EventListItem;
