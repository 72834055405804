import React from 'react';
import PageLayout from '../layout/PageLayout';
import SingleSignOnProviders from '../components/SingleSignOnProviders';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SignUpForm from '../forms/SignUpForm';

function SignUp() {
    const { t } = useTranslation();
    return (
        <PageLayout pageTitle={t('Sign-Up')} aside={<SingleSignOnProviders inOrUp="up" />}>
            <SignUpForm />
            <Box mt={1} textAlign="right">
                <Typography variant="body2">
                    {t('Already Have an Account?')}&nbsp;
                    <Link component={RouterLink} to="/auth/sign-in">
                        {t('Sign-In')}
                    </Link>
                </Typography>
            </Box>
        </PageLayout>
    );
}

SignUp.propTypes = {};
SignUp.defaultProps = {};

export default SignUp;
