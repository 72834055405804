import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

function EditButton(props) {
    const { url } = props;
    return (
        <IconButton component={RouterLink} to={url}>
            <EditIcon />
        </IconButton>
    );
}

EditButton.propTypes = {
    url: PropTypes.string.isRequired,
};
EditButton.defaultProps = {};

export default EditButton;
