import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import SplitButton from '../../core/buttons/SplitButton';
import Button from '../../core/buttons/Button';

const useStyles = makeStyles((theme) => ({
    button: {
        background: theme.palette.success.main,
        color: theme.palette.common.white,
        '&:hover': {
            background: theme.palette.success.light,
        },
    },
    splitButton: {
        '& > button': {
            background: theme.palette.success.main,
            color: theme.palette.common.white,
            '&:hover': {
                background: theme.palette.success.light,
            },
        },
    },
}));

function SaveButton(props) {
    const { isCreating, onSave, onSaveNew, children, ...otherProps } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    if (isCreating || !Boolean(onSaveNew)) {
        return (
            <Button
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={onSave}
                data-testid="form-save-button"
                {...otherProps}
            >
                {children || t(isCreating ? 'Create' : 'Update')}
            </Button>
        );
    }

    return (
        <SplitButton
            className={classes.splitButton}
            variant="contained"
            options={[t('Update'), t('Save as New')]}
            actions={[onSave, onSaveNew]}
            data-testid="form-save-button"
            {...otherProps}
        />
    );
}

SaveButton.propTypes = {
    isCreating: PropTypes.bool.isRequired,
    children: PropTypes.node,
    onSave: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).isRequired,
    onSaveNew: PropTypes.func,
};
SaveButton.defaultProps = {};

export default SaveButton;
