import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem } from '@mui/material';

function ListItemLink(props) {
    const { to, ...otherProps } = props;
    return <ListItem button component={RouterLink} to={to} {...otherProps} />;
}

ListItemLink.propTypes = {
    to: PropTypes.string.isRequired,
};
ListItemLink.defaultProps = {};

export default ListItemLink;
