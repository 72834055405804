import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from '../forms/CompanyForm';
import { properties } from '@hearmecheer/shared/models';
import FormContext from '../../core/providers/FormContext';
import PageLayout from '../../core/layouts/PageLayout';
import { authRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function CompanyForm() {
    const { user, hasCompany } = useAuthContext();
    const { push } = useHistory();
    const { accountId } = useParams();

    const sanitize = useCallback(
        (fields) => {
            return {
                ...fields,
                creator: user.email,
            };
        },
        [user.email],
    );

    const switchToCompany = useCallback(
        (fields) => {
            push(authRoutes.changeProperty.replace(':propertyId', fields.id));
        },
        [push],
    );

    return (
        <FormContext model={properties} modelId={accountId} beforeSave={sanitize} onSubmit={switchToCompany}>
            <PageLayout
                title={hasCompany ? 'Create Property' : 'Create Your First Property'}
                subtitle={'Properties are a convenient way to organize your embeds with your users'}
            >
                <Form />
            </PageLayout>
        </FormContext>
    );
}

CompanyForm.propTypes = {};
CompanyForm.defaultProps = {};

export default CompanyForm;
