import React from 'react';
import { Paper, Stack } from '@mui/material';

const FormLayout = (props) => (
    <Paper elevation={2} sx={{ p: 4 }}>
        <Stack spacing={2} {...props} />
    </Paper>
);

FormLayout.propTypes = {};
FormLayout.defaultProps = {};

export default FormLayout;
