import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debug from 'debug';
import PropTypes from 'prop-types';
import { cloneAndUpdate, omit } from '@hearmecheer/shared/Dict';
import { useSnackbar } from './SnackbarProvider';

const logger = debug('FormContext.js');

const FormContext = React.createContext({});
export const useFormContext = () => React.useContext(FormContext);

function FormContextProvider(props) {
    const { model, modelContext, modelId, beforeSave, onSubmit, onCancel, ...providerProps } = props;

    const { emitError, emitSuccess } = useSnackbar();
    const [loaded, setLoaded] = useState(false);
    const [fields, setFields] = useState({});

    const modelRef = useMemo(() => {
        return model.makeRefFromContext(modelContext, modelId);
    }, [model, modelContext, modelId]);

    const isCreating = typeof fields.id === 'undefined' || fields.id === null || fields.id === '';

    useEffect(() => {
        if (modelId) {
            logger('props.modelId found. fetching %s ID: %s', model.name, modelId);
            return model.getSnapshot(modelRef, (item) => {
                logger('item retrieved', item);
                setFields(item);
                setLoaded(true);
            });
        } else {
            logger('Creating template item');
            setFields(model.getDefaultValue());
            setLoaded(true);
        }
    }, [modelContext, model, modelId, modelRef]);

    const createItem = useCallback(async () => {
        try {
            const id = await model.genUniqueId(modelContext, omit(fields, ['id']));
            const sanitizedFields = beforeSave(fields);

            logger('Creating Item ID [', id, '] with fields: %j', sanitizedFields);
            await model.set(model.makeRefFromContext(modelContext, id), sanitizedFields);
            onSubmit({ ...sanitizedFields, id });
            emitSuccess('Created item successfully');
        } catch (err) {
            emitError(err.message);
        }
    }, [beforeSave, emitError, emitSuccess, fields, model, modelContext, onSubmit]);

    const updateItem = useCallback(async () => {
        logger('form.updateItem fields', fields);
        const sanitizedFields = beforeSave(fields);
        logger('sanitizedFields', sanitizedFields);
        await model.update(modelRef, sanitizedFields);
        emitSuccess('Updated item successfully');
        onSubmit(sanitizedFields);
    }, [beforeSave, emitSuccess, fields, model, modelRef, onSubmit]);

    const deleteItem = useCallback(async () => {
        await modelRef.delete();
        emitSuccess('Deleted item successfully');
        onCancel();
    }, [emitSuccess, modelRef, onCancel]);

    const updateField = useCallback((name, value) => {
        if (name.includes('.')) {
            // if there's dot notation, we're doing a nested update
            setFields((fields) => cloneAndUpdate(fields, name, value));
        } else {
            // regular update
            setFields((fields) => ({ ...fields, [name]: value }));
        }
    }, []);

    const fieldChange = useCallback(
        (e) => {
            const target = e.target;
            const { field } = target.dataset;
            let { type, value } = target;
            if (type === 'number') {
                value = Number(value);
            }
            updateField(field, value);
        },
        [updateField],
    );

    const fieldToggle = useCallback(
        (e) => {
            const { field } = e.target.dataset;
            const { checked } = e.target;
            logger('Field Toggle, setting %s to %s', field, checked);
            updateField(field, checked);
        },
        [updateField],
    );

    if (!loaded) {
        return null;
    }

    // if (!loaded) {
    //     return <LoadingBackdrop />;
    // }

    const context = {
        isCreating,
        fields,
        updateField,
        setFields,
        createItem,
        updateItem,
        deleteItem,
        cancel: onCancel,
        fieldChange,
        fieldToggle,
    };
    return <FormContext.Provider value={context} {...providerProps} />;
}

FormContextProvider.propTypes = {
    model: PropTypes.object.isRequired,
    modelContext: PropTypes.object,
    modelId: PropTypes.string,
    beforeSave: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};
FormContextProvider.defaultProps = {
    modelContext: {},
    beforeSave: (fields) => fields,
    onSubmit: () => null,
};

export default FormContextProvider;
