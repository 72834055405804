export default {
    'A server with that slug already exists!': 'A server with that slug already exists!',
    'Ad Link URL': 'Ad Link URL',
    'Ad Photo URL': 'Ad Photo URL',
    Ads: 'Ads',
    Advanced: 'Advanced',
    'Allow Audio Emotes': 'Allow Audio Emotes',
    'Allow Suite Creation': 'Allow Suite Creation',
    'An unexpected error occurred when trying to connect to the server':
        'An unexpected error occurred when trying to connect to the server',
    'Are you sure you want to delete this document? It cannot be undone':
        'Are you sure you want to delete this document? It cannot be undone',
    'Are you sure you want to delete this server? It cannot be undone':
        'Are you sure you want to delete this server? It cannot be undone',
    Arena: 'Arena',
    Asia: 'Asia',
    'Audio Track': 'Audio Track',
    Away: 'Away',
    'Away Team': 'Away Team',
    Audio: 'Audio',
    'Background Replay Time (seconds)': 'Background Replay Time (seconds)',
    'Background Track': 'Background Track',
    'Background Volume (0 - 10)': 'Background Volume (0 - 10)',
    'BBall Crowd 1': 'BBall Crowd 1',
    'Broadcast URL': 'Broadcast URL',
    Cancel: 'Cancel',
    Caption: 'Caption',
    'Click the button below to cheer': 'Click the button below to cheer',
    'Click the button to mute yourself, if it is grey your cheering is not heard.':
        'Click the button to mute yourself, if it is grey your cheering is not heard.',
    'Coming Soon': 'Coming Soon',
    'Connection URL': 'Connection URL',
    'Copied!': 'Copied!',
    'Copy and Paste the Link below': 'Copy and Paste the Link below',
    'Could not connect to the host securely': 'Could not connect to the host securely',
    'Could not find the game or game went offline': 'Could not find the game or game went offline',
    'Could not find the server or server went offline': 'Could not find the server or server went offline',
    'Countdown Enabled': 'Countdown Enabled',
    'Countdown Seconds': 'Countdown Seconds',
    'Create New': 'Create New',
    'Create a Suite': 'Create a Suite',
    'Create a suite and talk privately with family and friends':
        'Create a suite and talk privately with family and friends',
    'Creating New Team': 'Creating New Team',
    Crowd: 'Crowd',
    'Crowd 1': 'Crowd 1',
    'Crowd 2': 'Crowd 2',
    Clapping: 'Clapping',
    Custom: 'Custom',
    Date: 'Date',
    Delete: 'Delete',
    'Document created successfully': 'Document created successfully',
    'Document deleted successfully': 'Document deleted successfully',
    'Document updated successfully': 'Document updated successfully',
    "Don't Show Again": "Don't Show Again",
    'Editing {{name}}': 'Editing {{name}}',
    'End Date': 'End Date',
    'End Time': 'End Time',
    Email: 'Email',
    'Enable Background Audio': 'Enable Background Audio',
    Europe: 'Europe',
    'Export Ad Stats': 'Export Ad Stats',
    'Export Ad Stats (Host)': 'Export Ad Stats (Host)',
    'Export Ad Stats (All)': 'Export Ad Stats (All)',
    'Export Game Stats': 'Export Game Stats',
    'Export Game Stats (Host)': 'Export Game Stats (Host)',
    'Export Game Stats (All)': 'Export Game Stats (All)',
    'Failed to connect to server. Please try again later': 'Failed to connect to server. Please try again later',
    'Failed to play audio': 'Failed to play audio',
    Fans: 'Fans',
    'Find Your Game': 'Find Your Game',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        'Firefox is not supported, please switch to Chrome or Safari to continue.',
    Friends: 'Friends',
    'Friends List Enabled': 'Friends List Enabled',
    General: 'General',
    'Get Started': 'Get Started',
    'Hide Home/Away Titles': 'Hide Home/Away Titles',
    Home: 'Home',
    'Home Team': 'Home Team',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.',
    'Image URL': 'Image URL',
    'In order to get started, you first need to select your game':
        'In order to get started, you first need to select your game',
    'Ireland Call Harmony 1': 'Ireland Call Harmony 1',
    'Ireland Call Harmony 2': 'Ireland Call Harmony 2',
    'League Logo': 'League Logo',
    'League Logo Url': 'League Logo Url',
    'Logged in as {{email}}': 'Logged in as {{email}}',
    Login: 'Login',
    Logout: 'Logout',
    'Looks like the server is too busy at the moment. Please try again at a later time':
        'Looks like the server is too busy at the moment. Please try again at a later time',
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        'Looks like we unexpectedly lost connection. Please check your internet connection and try again later',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        "Looks like you're the first one here. Just know that you won't hear anything until someone else joins",
    Manual: 'Manual',
    'Mute All Client Output': 'Mute All Client Output',
    Name: 'Name',
    'New Server': 'New Server',
    None: 'None',
    'North America': 'North America',
    Notes: 'Notes',
    'Now that you have created a suite, invite your friends to join':
        'Now that you have created a suite, invite your friends to join',
    OR: 'OR',
    Ongoing: 'Ongoing',
    'Open Intro Dialog': 'Open Intro Dialog',
    'Ole Miss Sound': 'Ole Miss Sound',
    Password: 'Password',
    'Password Required': 'Password Required',
    Past: 'Past',
    'Person of Operations': 'Person of Operations',
    'Please use Safari on iOS': 'Please use Safari on iOS',
    'Press the button to begin cheering (microphone access required).':
        'Press the button to begin cheering (microphone access required).',
    Private: 'Private',
    Public: 'Public',
    'RBNY HMC Backing': 'RBNY HMC Backing',
    'Sample Crowd': 'Sample Crowd',
    Save: 'Save',
    'Save as New': 'Save as New',
    Scheduled: 'Scheduled',
    'Schedule Enabled': 'Schedule Enabled',
    'Second Ad Link URL': 'Second Ad Link URL',
    'Second Ad Photo URL': 'Second Ad Photo URL',
    'Server Location': 'Server Location',
    'Server created successfully': 'Server created successfully',
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'Server is offline. Could not establish connection. Please check your internet connection and try again later',
    'Server updated successfully': 'Server updated successfully',
    'Set Replay Timer': 'Set Replay Timer',
    Share: 'Share',
    'Show Replies to Tweets in Feed': 'Show Replies to Tweets in Feed',
    Slug: 'Slug',
    'Staff Login': 'Staff Login',
    'Start Date': 'Start Date',
    'Start Time': 'Start Time',
    'Select a team to root for!': 'Select a team to root for!',
    'Third Ad Link URL': 'Third Ad Link URL',
    'Third Ad Photo URL': 'Third Ad Photo URL',
    'This game is Private and not viewable to the public': 'This game is Private and not viewable to the public',
    Time: 'Time',
    'Twitter Handle (case-sensitive)': 'Twitter Handle (case-sensitive)',
    'Unable to find or connect to microphone': 'Unable to find or connect to microphone',
    Upcoming: 'Upcoming',
    'Use the small button to turn off local audio output.': 'Use the small button to turn off local audio output.',
    Visibility: 'Visibility',
    'Welcome to HearMeCheer!': 'Welcome to HearMeCheer!',
    'Welcome to a Private Suite!': 'Welcome to a Private Suite!',
    'When the button is pink, the microphone is on and your cheering is live.':
        'When the button is pink, the microphone is on and your cheering is live.',
    'Video Enabled': 'Video Enabled',
    'Media URL': 'Media URL',
    Header: 'Header',
    'Game Title': 'Game Title',
    'Team Overview': 'Team Overview',
    'Team Volume': 'Team Volume',
    'Your cheering and clapping can be heard by others while your mic is on.':
        'Your cheering and clapping can be heard by others while your mic is on.',
    '{{friends}} Friends Cheering': '{{friends}} Friends Cheering',
    Video: 'Video',
    'HTML code': 'HTML code',
    Parse: 'Parse',
    'Media Type': 'Media Type',
    'Paste Code': 'Paste Code',
    'Add Permission': 'Add Permission',
    'Video Key': 'Video Key',
    'Channel Key': 'Channel Key',
    'Twitch Handle': 'Twitch Handle',
    'Be Safe! Only use code you trust!': 'Be Safe! Only use code you trust!',
    'Was not able to parse the HTML code.': 'Was not able to parse the HTML code.',
    'Video Media': 'Video Media',
    'Chat Media': 'Chat Media',
    "Not sure you're allowed to be here...": "Not sure you're allowed to be here...",
    Users: 'Users',
    Role: 'Role',
    Admin: 'Admin',
    Moderator: 'Moderator',
    Create: 'Create',
    Update: 'Update',
    'View Page': 'View Page',
    'View Embed Preview': 'View Embed Preview',
    'Embed Code': 'Embed Code',
    Moderate: 'Moderate',
    'Make public': 'Make public',
    'Make private': 'Make private',
    'Current Status': 'Current Status',
    public: 'public',
    private: 'private',
    'Going online {{distance}}': 'Going online {{distance}}',
    'Switching to offline {{distance}}': 'Switching to offline {{distance}}',
    'Online Indefinitely': 'Online Indefinitely',
    'Went Offline {{distance}}': 'Went Offline {{distance}}',
    'Currently logged in as {{email}}': 'Currently logged in as {{email}}',
    'Not you?': 'Not you?',
    'Password Reset': 'Password Reset',
    'Send Password Reset Email': 'Send Password Reset Email',
    'Are you sure you want to send a password reset email to this email?':
        'Are you sure you want to send a password reset email to this email?',
    Send: 'Send',
    Games: 'Games',
    Stats: 'Stats',
    Homepage: 'Homepage',
};
