import React from 'react';
import PropTypes from 'prop-types';
import VolumeDownIcon from '@mui/icons-material/VolumeDownOutlined';
import VolumeMuteIcon from '@mui/icons-material/VolumeMuteOutlined';
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';

function VolumeIcon(props) {
    const { volume } = props;
    if (volume === 0) {
        return <VolumeOffIcon />;
    }
    if (volume < 0.25) {
        return <VolumeMuteIcon />;
    }
    if (volume < 0.5) {
        return <VolumeDownIcon />;
    }
    return <VolumeUpIcon />;
}

VolumeIcon.propTypes = {
    volume: PropTypes.number.isRequired,
};
VolumeIcon.defaultProps = {};

export default VolumeIcon;
