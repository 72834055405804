export default {
    'A server with that slug already exists!': '¡Ya existe un servidor con ese slug!',
    'Ad Link URL': 'URL del enlace del anuncio',
    'Ad Photo URL': 'URL de la foto del anuncio',
    Ads: 'Anuncios',
    Advanced: 'Avanzado',
    'Are you sure you want to delete this document? It cannot be undone':
        '¿Está seguro de que desea eliminar este documento? No se puede deshacer',
    'Are you sure you want to delete this server? It cannot be undone':
        '¿Está seguro de que desea eliminar este servidor? No se puede deshacer',
    Arena: 'Estadio',
    Away: 'Visitante',
    'Away Team': 'Equipo de fuera',
    Cancel: 'Cancelar',
    Caption: 'Subtítulo',
    'Click the button below to cheer': 'Haga clic en el botón de abajo para alentar',
    'Click the pink button to mute yourself, if it is grey your cheering is not heard':
        'Haga clic en el botón rosa para silenciarse, si es gris, no se escucha su aliento',
    'Coming Soon': 'Próximamente',
    'Connection URL': 'URL de conexión',
    'Could not connect to the host securely': 'No se pudo conectar al host de forma segura',
    'Could not find the server or server went offline': 'No se pudo encontrar el servidor o el servidor se desconectó',
    'Create New': 'Crear nuevo',
    'Creating New Team': 'Creando un nuevo equipo',
    Date: 'Fecha',
    Delete: 'Eliminar',
    'Document created successfully': 'Documento creado con éxito',
    'Document deleted successfully': 'Documento eliminado con éxito',
    'Document updated successfully': 'Documento actualizado con éxito',
    "Don't Show Again": 'Documento actualizado con éxito',
    'Editing {{name}}': 'Edición {{name}}',
    Email: 'Email',
    Fans: 'Aficionados',
    'Find Your Game': 'Encuentra tu juego',
    'Firefox is not supported, please switch to Chrome or Safari to continue.':
        'Firefox no es compatible, cambie a Chrome o Safari para continuar',
    Friends: 'Amigos',
    'Friends List Enabled': 'Lista de amigos habilitada',
    General: 'General',
    'Get Started': 'Empezar',
    Home: 'Local',
    'Home Team': 'Equipo local',
    'If you do not hear any volume, or the volume is low, try turning the microphone off and on again by pressing the pink button below.':
        'Si no escucha ningún volumen, o el volumen es bajo, intente apagar y encender el micrófono nuevamente presionando el botón rosa que se encuentra debajo',
    'Image URL': 'URL de la imagen',
    'League Logo': 'Logotipo de la liga',
    'League Logo Url': 'URL del logotipo de la liga',
    'Logged in as {{email}}': 'Iniciado sesión como {{email}}',
    Login: 'Iniciar sesión',
    Logout: 'Cerrar sesión',
    'Looks like we unexpectedly lost connection. Please check your internet connection and try again later':
        'Parece que inesperadamente perdimos la conexión. Comprueba tu conexión a Internet y vuelve a intentarlo más tarde',
    "Looks like you're the first one here. Just know that you won't hear anything until someone else joins":
        'Parece que eres el primero aquí. No escucharás nada hasta que alguien más se una',
    Name: 'Batata',
    'New Server': 'Nuevo servidor',
    None: 'Ninguna',
    Password: 'Contraseña',
    'Please use Safari on iOS': 'Utilice Safari en iOS',
    'Press the button to begin cheering (microphone access required).':
        'Presione el botón para comenzar a animar (se requiere acceso al micrófono).',
    Save: 'Salvar',
    'Second Ad Link URL': 'Segunda URL del enlace del anuncio',
    'Second Ad Photo URL': 'URL de la segunda foto del anuncio',
    'Server created successfully': 'Servidor creado con éxito',
    'Server is offline. Could not establish connection. Please check your internet connection and try again later':
        'El servidor está fuera de línea. No se pudo establecer la conexión. Compruebe su conexión a Internet y vuelva a intentarlo más tarde',
    'Server updated successfully': 'Servidor actualizado correctamente',
    Share: 'Compartir',
    Slug: 'Slug',
    'Staff Login': 'Inicio de sesión del personal',
    'Third Ad Link URL': 'Tercera URL de enlace de anuncio',
    'Third Ad Photo URL': 'Tercera URL de la foto del anuncio',
    Time: 'Hora',
    'Twitter Handle (case-sensitive)': 'Twitter Handle (distingue entre mayúsculas y minúsculas)',
    'Unable to find or connect to microphone': 'No se puede encontrar o conectarse al micrófono',
    'Use the small button to turn off local audio output.':
        'Usa el pequeño botón del micrófono junto al icono rosa para apagar la salida de audio local',
    'Welcome to HearMeCheer!': 'Bienvenido a HearMeCheer!',
    'When the button is pink, the microphone is on and your cheering is live':
        'Cuando el botón es rosa, el micrófono está encendido',
    'Your cheering and clapping can be heard by others while your mic is on.':
        'Sus vítores y aplausos pueden ser escuchados por otros mientras su micrófono está encendido',
    '{{friends}} Friends Cheering': '{{friends}} Amigos Animado',
};
