import { useGameContext } from '../providers/GameContext';
import React, { useCallback, useEffect, useState } from 'react';
import TextField from '../../core/inputs/TextField';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const GameSearch = () => {
    const { searchQuery, search } = useGameContext();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(searchQuery);
    }, [searchQuery]);

    const handleChange = useCallback((e) => {
        const { value } = e.target;
        setValue(value);
    }, []);

    useEffect(() => {
        if (value !== searchQuery) {
            const timerId = setTimeout(() => search(value), 600);
            return () => clearTimeout(timerId);
        }
    }, [search, searchQuery, value]);

    const clearSearch = useCallback(() => {
        search('');
    }, [search]);

    return (
        <TextField
            value={value}
            onChange={handleChange}
            placeholder="Search…"
            InputProps={{
                startAdornment: <SearchIcon fontSize="small" />,
                endAdornment: (
                    <IconButton
                        title="Clear"
                        aria-label="Clear"
                        size="small"
                        sx={{ visibility: value ? 'visible' : 'hidden' }}
                        onClick={clearSearch}
                    >
                        <ClearIcon fontSize="small" />
                    </IconButton>
                ),
            }}
        />
    );
};

export default GameSearch;
