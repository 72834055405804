import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPopover = styled(Popover)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 1000,
        padding: theme.spacing(1),
    },
}));

/**
 * A Popover intended to appear on button onClick events for controls
 * on a list item
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
function ControlsPopover(props) {
    const { anchorEl, ...popoverProps } = props;
    return (
        <StyledPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            {...popoverProps}
        />
    );
}

ControlsPopover.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};
ControlsPopover.defaultProps = {};

export default ControlsPopover;
