import React from 'react';
import { makeStyles } from '@mui/styles';
import Icon from './GoogleIcon.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
}));

function GoogleIcon(props) {
    const classes = useStyles();
    return <img src={Icon} alt={'Google Logo'} className={classes.root} />;
}

GoogleIcon.propTypes = {};
GoogleIcon.defaultProps = {};

export default GoogleIcon;
