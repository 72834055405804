import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LoadingBackdrop = styled((props) => (
    <Backdrop {...props}>
        <CircularProgress color="primary" />
    </Backdrop>
))(({ theme }) => ({ zIndex: theme.zIndex.drawer + 2 })); // drawerNav.zIndex + 1

LoadingBackdrop.defaultProps = {
    open: true,
};

export default LoadingBackdrop;
