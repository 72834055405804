import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListView from './ListView';
import EventListItem from '../lists/EventListItem';
import { events } from '@hearmecheer/shared/models';
import SectionTitle from '../components/SectionTitle';
import { Typography } from '@mui/material';
import SimpleDialog from '../../core/dialogs/SimpleDialog';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function EventList() {
    const { company } = useAuthContext();
    const { t } = useTranslation();
    const [createEventDialog, setCreateEventDialog] = useState(false);
    const [name, setName] = useState('');

    const showCreateEventDialog = useCallback(() => {
        setCreateEventDialog(true);
    }, []);

    const hideCreateEventDialog = useCallback(() => {
        setCreateEventDialog(false);
    }, []);

    const createEvent = useCallback(async () => {
        const modelContext = { propertyId: company.id };
        const body = { name };
        const uniqueId = await events.genUniqueId(modelContext, body);
        await events.set(events.makeRef(company.id, uniqueId), body);
        hideCreateEventDialog();
    }, [company.id, hideCreateEventDialog, name]);

    const handleNameChange = useCallback((e) => {
        const { value } = e.target;
        setName(value);
    }, []);

    const renderModel = useCallback(
        (model, context) => <EventListItem key={model.id} model={model} context={context} />,
        [],
    );

    return (
        <ListView
            title={t('Events')}
            onCreate={showCreateEventDialog}
            modelRef={events.makeRef(company.id)}
            useListCards={false}
            renderModelItem={renderModel}
            renderEmptyList={
                <div>
                    <SectionTitle title="Create your first event" />
                    <Button color={'secondary'} onClick={showCreateEventDialog}>
                        Get Started
                    </Button>
                </div>
            }
        >
            <SimpleDialog
                open={createEventDialog}
                onClose={hideCreateEventDialog}
                title={'Create New Event'}
                body={
                    <div>
                        <Typography variant={'body2'}>Enter a unique name for the event</Typography>
                        <TextField value={name} onChange={handleNameChange} onEnter={createEvent} />
                    </div>
                }
                actions={
                    <div>
                        <Button flat onClick={hideCreateEventDialog}>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={createEvent}>
                            Create
                        </Button>
                    </div>
                }
            />
        </ListView>
    );
}

EventList.propTypes = {};
EventList.defaultProps = {};

export default EventList;
