import debug from 'debug';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { events, participants, rooms, tracks } from '@hearmecheer/shared/models';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { db } from '@hearmecheer/shared/firebase/client';
import { eventRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { omit } from '@hearmecheer/shared/Dict';

const logger = debug('GameContext');

const GameContext = React.createContext({});
export const useGameContext = () => React.useContext(GameContext);

const escapeRegex = (value) => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

const initContext = () => ({
    game: {},
    gameLoaded: false,
    gameFound: false,
    roomKey: {},
    roomList: [],
    roomListLoaded: false,
    participantList: [],
    participantListLoaded: false,
    defaultRoomInitialized: false,
    trackList: [],
    trackListLoaded: false,
    contextReady: false,

    // search
    isSearching: false,
    searchQuery: '',
    search: null, // func
    participantListFiltered: [],
});

const participantSort = (firstParticipant, secondParticipant) => {
    if (firstParticipant.reports.length || secondParticipant.reports.length) {
        return secondParticipant.reports.length - firstParticipant.reports.length;
    }

    if (firstParticipant.isHandRaised) {
        return secondParticipant.isHandRaised ? 0 : -1;
    }
    if (secondParticipant.isHandRaised) {
        return 1;
    }
    return firstParticipant.name.localeCompare(secondParticipant.name);
};

const contextReducer = (state, action) => {
    logger('Dispatching Action', action);
    switch (action.type) {
        case '404':
            return { ...state, gameLoaded: true, gameFound: false };
        case 'GAME':
            return {
                ...state,
                gameLoaded: true,
                game: action.game,
                gameFound: true,
            };
        case 'ROOM_LIST': {
            const { roomList } = action;
            const { game } = state;
            const [primaryRoomList, partyRoomList] = roomList.reduce(
                (aggr, room) => {
                    const [primaryRoomList, partyRoomList] = aggr;
                    (room.isParty ? partyRoomList : primaryRoomList).push(room);
                    return aggr;
                },
                [[], []],
            );
            return {
                ...state,
                roomListLoaded: true,
                roomList,
                roomKey: roomList.reduce((dict, room) => {
                    dict[room.id] = room;
                    return dict;
                }, {}),
                primaryRoomList: primaryRoomList.sort((firstRoom, secondRoom) => {
                    if (game.defaultRoom === firstRoom.id) {
                        return -1;
                    }
                    if (game.defaultRoom === secondRoom.id) {
                        return 1;
                    }

                    const isFirstRoomBroadcasting = game.broadcastRooms.includes(firstRoom.id);
                    const isSecondRoomBroadcasting = game.broadcastRooms.includes(secondRoom.id);

                    if (isFirstRoomBroadcasting && !isSecondRoomBroadcasting) {
                        return -1;
                    }
                    if (isSecondRoomBroadcasting && !isFirstRoomBroadcasting) {
                        return 1;
                    }

                    return firstRoom.name.localeCompare(secondRoom.name);
                }),
                partyRoomList: partyRoomList.sort((a, b) => a.name.localeCompare(b.name)),
            };
        }
        case 'PARTICIPANT_LIST':
            return {
                ...state,
                participantListLoaded: true,
                participantList: [...action.participantList].sort(participantSort),
            };
        case 'ADD_PARTICIPANT': {
            return {
                ...state,
                participantList: [...state.participantList, action.participant].sort(participantSort),
            };
        }
        case 'CHANGE_PARTICIPANT': {
            return {
                ...state,
                participantList: state.participantList
                    .map((participant) => {
                        if (participant.id === action.participant.id) {
                            return action.participant;
                        }
                        return participant;
                    })
                    .sort(participantSort),
            };
        }
        case 'DELETE_PARTICIPANT': {
            return {
                ...state,
                participantList: state.participantList.filter((participant) => participant.id !== action.participantId),
            };
        }
        case 'DEFAULT_ROOM_INITIALIZED':
            return {
                ...state,
                defaultRoomInitialized: true,
            };
        case 'SEARCH': {
            const { query } = action;
            if (!query) {
                return {
                    ...state,
                    isSearching: false,
                    searchQuery: '',
                    participantListFiltered: [],
                };
            }
            return {
                ...state,
                isSearching: true,
                searchQuery: query,
                // participantListFiltered filled by effect
            };
        }
        case 'CONTEXT_READY':
            return { ...state, contextReady: true };
        case 'TRACK_LIST':
            return { ...state, trackList: action.payload };
        case 'RESET':
            return initContext();
        case 'ADD_CONTEXT':
            return { ...state, ...omit(action, ['type']) };
        default:
            return state;
    }
};

const GameContextProvider = (props) => {
    const { ...providerProps } = props;
    const { company } = useAuthContext();
    const history = useHistory();
    const { eventId } = useParams();

    const [context, dispatchContext] = useReducer(contextReducer, {}, initContext);

    const {
        game,
        gameLoaded,
        gameFound,
        roomKey,
        roomListLoaded,
        participantList,
        participantListLoaded,
        defaultRoomInitialized,
        contextReady,
        isSearching,
        searchQuery,
    } = context;

    useEffect(() => {
        if (gameLoaded && !gameFound) {
            history.push(eventRoutes.list);
        }
    }, [gameLoaded, gameFound, history]);

    useEffect(() => {
        if (!eventId) {
            return;
        }
        return events.getSnapshot(events.makeRef(company.id, eventId), (game) => {
            if (game.id) {
                dispatchContext({ type: 'GAME', game });
            } else {
                dispatchContext({ type: '404' });
            }
        });
    }, [eventId, company.id]);

    useEffect(() => {
        if (!gameFound) {
            return;
        }
        return rooms.snapshot(rooms.makeRef(company.id, eventId).where('isParty', '==', false), {
            onSnapshot: (roomList) => dispatchContext({ type: 'ROOM_LIST', roomList }),
        });
        // return rooms.listSnapshot(rooms.makeRef(company.id, eventId).where('isParty', '==', false), (roomList) =>
        //     dispatchContext({ type: 'ROOM_LIST', roomList }),
        // );
    }, [company.id, eventId, gameFound]);

    useEffect(() => {
        if (gameFound) {
            return tracks.snapshot(tracks.makeRef(company.id, eventId), {
                onSnapshot: (trackList) => dispatchContext({ type: 'TRACK_LIST', payload: trackList }),
            });
        }
    }, [gameFound, company.id, eventId]);

    useEffect(() => {
        if (!gameFound) {
            return;
        }

        logger('Loading participant list listener');

        let firstInvoke = true;
        return participants
            .makeRef(company.id, eventId)
            .where('connectionCount', '>', 0)
            .onSnapshot((snapshot) => {
                if (firstInvoke) {
                    dispatchContext({
                        type: 'PARTICIPANT_LIST',
                        participantList: snapshot.docs.map((d) => participants.makeItem(d.data())),
                    });
                    firstInvoke = false;
                    return;
                }
                logger('participants snapshot called');
                snapshot.docChanges().forEach((change) => {
                    const participant = participants.makeItem(change.doc.data());
                    if (change.type === 'added') {
                        dispatchContext({
                            type: 'ADD_PARTICIPANT',
                            participant,
                        });
                    } else if (change.type === 'modified') {
                        dispatchContext({
                            type: 'CHANGE_PARTICIPANT',
                            participant,
                        });
                    } else if (change.type === 'removed') {
                        dispatchContext({
                            type: 'DELETE_PARTICIPANT',
                            participantId: change.doc.id,
                        });
                    }
                });
            });

        // return participants.listSnapshot(
        //     participants.makeRef(company.id, eventId),
        //     (participantList) =>
        //         dispatchContext({ type: 'PARTICIPANT_LIST', participantList }),
        // );
    }, [company.id, eventId, gameFound]);

    // make sure the default room and broadcast rooms exists
    useEffect(() => {
        if (!gameFound || !roomListLoaded || !participantListLoaded) {
            return;
        }
        logger('Checking void rooms - should only run once');

        const roomExists = (roomId) => typeof roomKey[roomId] !== 'undefined';

        const createRoom = (roomId) =>
            rooms.set(rooms.makeRef(company.id, eventId, roomId), {
                name: roomId,
            });

        if (!roomExists(game.defaultRoom)) {
            createRoom(game.defaultRoom);
        }

        for (const roomId of game.broadcastRooms) {
            if (!roomExists(roomId)) {
                createRoom(roomId);
            }
        }

        dispatchContext({ type: 'DEFAULT_ROOM_INITIALIZED' });
    }, [
        company.id,
        eventId,
        game.broadcastRooms,
        game.defaultRoom,
        gameFound,
        participantListLoaded,
        roomKey,
        roomListLoaded,
    ]);

    // find void participants and assign them to the defaultRoom
    useEffect(() => {
        if (!defaultRoomInitialized) {
            return;
        }

        if (!participantList.length) {
            dispatchContext({ type: 'CONTEXT_READY' });
            return;
        }

        logger('Finding void participants and updating their rooms');
        const batch = db.batch();
        let shouldUpdate = false;
        for (const participant of participantList) {
            if (!roomKey[participant.primaryRoom]) {
                shouldUpdate = true;
                const roomsMap = participants.makeRoomsMap(game, participant, true);

                logger('creating update with:', {
                    propertyId: company.id,
                    eventId,
                    participantId: participant.id,
                });

                batch.update(participants.makeRef(company.id, eventId, participant.id), {
                    primaryRoom: game.defaultRoom,
                    rooms: roomsMap,
                });
            }
        }

        if (shouldUpdate) {
            logger('Found %s void participants. Moving to default room', participantList.length);
            batch.commit().then(() => dispatchContext({ type: 'CONTEXT_READY' }));
        } else {
            logger('No Void participants found.');
            dispatchContext({ type: 'CONTEXT_READY' });
        }
    }, [company.id, defaultRoomInitialized, eventId, game, participantList, roomKey]);

    useEffect(() => {
        if (isSearching) {
            let fields = participants.fields;
            let query = searchQuery;

            const matches = searchQuery.match(/^([A-Za-z]+)=/);
            if (matches) {
                const fieldName = matches[1];
                fields = participants.fields.filter((field) => field.name === fieldName);
                query = searchQuery.substr(fieldName.length + 1);
            }

            const searchRegex = new RegExp(escapeRegex(query), 'i');
            const participantListFiltered = participantList.filter((participant) =>
                fields.find((field) => participant[field.name] && searchRegex.test(participant[field.name].toString())),
            );
            dispatchContext({ type: 'ADD_CONTEXT', participantListFiltered });
        }
    }, [isSearching, participantList, searchQuery]);

    const search = useCallback((query) => dispatchContext({ type: 'SEARCH', query }), []);

    useEffect(() => {
        dispatchContext({ type: 'ADD_CONTEXT', search });
    }, [search]);

    if (!contextReady) {
        return <LoadingBackdrop />;
    }

    return <GameContext.Provider value={context} {...providerProps} />;
};

GameContextProvider.propTypes = {};
GameContextProvider.defaultProps = {};

export default GameContextProvider;
