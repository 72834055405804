import React from 'react';
import PropTypes from 'prop-types';
import { Mic, MicOff } from '@mui/icons-material';

function MicIcon(props) {
    const { volume } = props;
    if (volume === 0) {
        return <MicOff color="error" />;
    }
    return <Mic />;
}

MicIcon.propTypes = {
    volume: PropTypes.number,
};
MicIcon.defaultProps = {};

export default MicIcon;
