import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar as MuiAppBar,
    Box,
    Divider,
    Drawer as MuiDrawer,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Stack,
    Toolbar,
    Typography,
} from '@mui/material';
import {
    AddModerator as AutoModeratorIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    Event as EventIcon,
    Group as GroupIcon,
    Menu as MenuIcon,
    VpnKey as VpnKeyIcon,
    Web as WebIcon,
} from '@mui/icons-material';
import { useAppContext } from '../../admin/providers/AppContextProvider';
import AccountUserMenu from '../../auth/layout/AccountMenu';
import ListItemLink from '../lists/ListItemLink';
import Logo from '../components/Logo';
import { apiRoutes, automodRoutes, eventRoutes, profileRoutes } from '../RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import PropertyAutocomplete from '../components/PropertyAutocomplete';

const drawerWidth = 250;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    background: `linear-gradient(to right, ${theme.palette.secondary.dark}, ${theme.palette.primary.dark})`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const MainContent = styled('main')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
}));

const DrawerNav = (props) => {
    const { children } = props;
    const { pageTitle, pageDescription } = useAppContext();
    const { isAuthed, hasRole } = useAuthContext();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleDrawerClose = useCallback(() => {
        setOpen(false);
    }, []);

    if (!isAuthed) {
        return <MainContent>{children}</MainContent>;
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {pageTitle}
                    </Typography>
                    <Stack sx={{ ml: 'auto' }} direction={'row'}>
                        {hasRole('super') && <PropertyAutocomplete />}
                        <AccountUserMenu />
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <Logo />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItemLink to={eventRoutes.list}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Events'} primaryTypographyProps={{ variant: 'h5' }} />
                    </ListItemLink>
                    {hasRole('dev') && (
                        <ListItemLink to={automodRoutes.home}>
                            <ListItemIcon>
                                <AutoModeratorIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Auto Moderators'} primaryTypographyProps={{ variant: 'h5' }} />
                        </ListItemLink>
                    )}
                    {hasRole('admin') && (
                        <ListItemLink to={profileRoutes.list}>
                            <ListItemIcon>
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Users'} primaryTypographyProps={{ variant: 'h5' }} />
                        </ListItemLink>
                    )}
                    {hasRole('admin') && (
                        <ListItemLink to={apiRoutes.keys}>
                            <ListItemIcon>
                                <VpnKeyIcon />
                            </ListItemIcon>
                            <ListItemText primary={'API'} primaryTypographyProps={{ variant: 'h5' }} />
                        </ListItemLink>
                    )}
                    {hasRole('admin') && (
                        <ListItemLink to={apiRoutes.sites}>
                            <ListItemIcon>
                                <WebIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Domains'} primaryTypographyProps={{ variant: 'h5' }} />
                        </ListItemLink>
                    )}
                </List>
            </Drawer>
            <MainContent>
                <DrawerHeader />
                <Typography variant={'body1'} mb={3} sx={{ ...(!pageDescription && { display: 'none' }) }}>
                    {pageDescription}
                </Typography>
                {children}
            </MainContent>
        </Box>
    );
};

DrawerNav.propTypes = {
    children: PropTypes.node,
};

export default DrawerNav;
