import React from 'react';
import PropTypes from 'prop-types';
import ListCard from './ListCard';
import EditButton from '../../core/buttons/EditButton';
import ExpandMenuButton from '../../core/buttons/ExpandMenuButton';
import UserAvatar from '../../auth/components/UserAvatar';
import { profileRoutes } from '../../core/RoutesMap';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function UserListItem(props) {
    const { model, context } = props;
    const { id, email, displayName, photoURL, role } = model;
    const { hasRole } = useAuthContext();
    return (
        <ListCard
            icon={<UserAvatar src={photoURL} name={displayName || email} />}
            title={displayName || email}
            subtitle={role}
            actions={
                <div>
                    {(role !== 'owner' || hasRole('super')) && (
                        <EditButton url={profileRoutes.edit.replace(':userId', id)} />
                    )}
                    {context.shouldHaveMenu && <ExpandMenuButton id={id} onClick={context.openActionMenu} />}
                </div>
            }
        />
    );
}

UserListItem.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.string,
        email: PropTypes.string,
        displayName: PropTypes.string,
        photoURL: PropTypes.string,
        role: PropTypes.string,
    }).isRequired,
    context: PropTypes.shape({
        shouldHaveMenu: PropTypes.bool,
        openActionMenu: PropTypes.func,
    }).isRequired,
};
UserListItem.defaultProps = {};

export default UserListItem;
