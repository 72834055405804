import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ListView from './ListView';
import UserListItem from '../lists/UserListItem';
import { profiles } from '@hearmecheer/shared/models';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import { profileRoutes } from '../../core/RoutesMap';

function UserList() {
    const { company } = useAuthContext();
    const { t } = useTranslation();

    const renderModelItem = useCallback(
        (model, context) => <UserListItem key={model.id} model={model} context={context} />,
        [],
    );

    return (
        <ListView
            title={t('Users')}
            createUrl={profileRoutes.create}
            modelRef={profiles.makeRef(company.id)}
            renderModelItem={renderModelItem}
            renderEmptyList={'Abandoned (No Users)'}
        />
    );
}

UserList.propTypes = {};
UserList.defaultProps = {};

export default UserList;
