import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
} from '@mui/material';
import {
    Delete as DeleteIcon,
    Group as GroupIcon,
    HearingDisabled as MuffleIcon,
    MoreVert as MoreVertIcon,
    Podcasts as PodcastsIcon,
    QueueMusic as QueueMusicIcon,
    Stream as StreamIcon,
} from '@mui/icons-material';
import { green, blue } from '@mui/material/colors';
import { firebase } from '@hearmecheer/shared/firebase/client';
import { events, rooms } from '@hearmecheer/shared/models';
import ParticipantListItem from './ParticipantListItem';
import Editable from '../components/Editable';
import ConfirmDialog from '../../core/dialogs/ConfirmDialog';
import { useGameContext } from '../providers/GameContext';
import PluralLabel from '../components/PluralLabel';
import BackgroundAudioDialog from '../components/BackgroundAudioDialog';
import VolumeButton from '../components/VolumeButton';
import ControlsPopover from '../components/ControlsPopover';
import VolumeSlider from '../../core/components/VolumeSlider';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function RoomGridItem(props) {
    const { room, participantList } = props;
    const { company } = useAuthContext();
    const { game } = useGameContext();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [volumeAnchorEl, setVolumeAnchorEl] = useState(null);
    const [backgroundAudioDialog, setBackgroundAudioDialog] = useState(false);
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);

    const isDefault = game.defaultRoom === room.id;
    const isBroadcasting = game.broadcastRooms.includes(room.id);

    const modelContext = useMemo(() => {
        return {
            propertyId: company.id,
            eventId: game.id,
            roomId: room.id,
        };
    }, [company.id, game.id, room.id]);

    const handleNameChange = useCallback(
        (name) => {
            rooms.update(rooms.makeRef(modelContext), { name });
        },
        [modelContext],
    );

    const showMenu = useCallback((e) => {
        setMenuAnchorEl(e.target);
    }, []);

    const hideMenu = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);

    const showVolumePopover = useCallback((e) => {
        setVolumeAnchorEl(e.currentTarget);
    }, []);

    const hideVolumePopover = useCallback(() => {
        setVolumeAnchorEl(null);
    }, []);

    const showBackgroundAudioDialog = useCallback(() => {
        setBackgroundAudioDialog(true);
    }, []);

    const hideBackgroundAudioDialog = useCallback(() => {
        setBackgroundAudioDialog(false);
    }, []);

    const handleAudioChange = useCallback(
        (backgroundAudio) => {
            rooms.update(rooms.makeRef(modelContext), { backgroundAudio });
        },
        [modelContext],
    );

    const showConfirmDeleteDialog = useCallback(() => {
        setConfirmDeleteDialog(true);
    }, []);

    const hideConfirmDeleteDialog = useCallback(() => {
        setConfirmDeleteDialog(false);
    }, []);

    const handleVolumeChange = useCallback(
        (volume) => {
            rooms.update(rooms.makeRef(modelContext), { gain: volume });
        },
        [modelContext],
    );

    const toggleMuffling = useCallback(() => {
        return rooms.update(rooms.makeRef(modelContext), {
            muffled: !room.muffled,
        });
    }, [modelContext, room.muffled]);

    const toggleSteaming = useCallback(() => {
        return rooms.update(rooms.makeRef(modelContext), {
            allowStreaming: !room.allowStreaming,
        });
    }, [modelContext, room.allowStreaming]);

    const toggleBroadcasting = useCallback(async () => {
        const broadcastRooms = (
            isBroadcasting ? firebase.firestore.FieldValue.arrayRemove : firebase.firestore.FieldValue.arrayUnion
        )(room.id);
        await events.update(events.makeRef(modelContext), { broadcastRooms });
    }, [isBroadcasting, modelContext, room.id]);

    const setAsDefault = useCallback(() => {
        return events.update(events.makeRef(modelContext), {
            defaultRoom: room.id,
        });
    }, [modelContext, room.id]);

    const deleteRoom = useCallback(async () => {
        if (isBroadcasting) {
            await toggleBroadcasting();
        }
        setConfirmDeleteDialog(false);
        await rooms.delete(rooms.makeRef(modelContext));
    }, [isBroadcasting, modelContext, toggleBroadcasting]);

    const renderParticipant = useCallback((participant, index, list) => {
        return (
            <ParticipantListItem key={participant.id} participant={participant} divider={index <= list.length - 2} />
        );
    }, []);

    return (
        <Grid key={room.id} item xs={12} md={6} lg={4}>
            <Card>
                <CardHeader
                    title={
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            {room.backgroundAudio?.enabled && (
                                <Tooltip arrow title={'This room is playing background track'}>
                                    <QueueMusicIcon sx={{ color: blue[500] }} />
                                </Tooltip>
                            )}
                            {isBroadcasting && (
                                <Tooltip arrow title={'Broadcasting Rooms can be heard by everyone'}>
                                    <PodcastsIcon color="primary" />
                                </Tooltip>
                            )}
                            {isDefault && (
                                <Tooltip arrow title={'New participants will join this room'}>
                                    <GroupIcon />
                                </Tooltip>
                            )}
                            {room.allowStreaming && (
                                <Tooltip arrow title="Audio can be heard by anyone with the stream URL">
                                    <StreamIcon sx={{ color: green[500] }} />
                                </Tooltip>
                            )}
                            {room.muffled && (
                                <Tooltip arrow title={'Audio Muffling'}>
                                    <MuffleIcon />
                                </Tooltip>
                            )}
                            <Editable value={room.name} onSubmit={handleNameChange}>
                                {room.name}
                            </Editable>
                        </Stack>
                    }
                    subheader={<PluralLabel number={participantList.length} singular={'participant'} />}
                    action={
                        room.isParty ? (
                            <Stack spacing={2}>
                                <Chip label="Private" color="primary" />
                            </Stack>
                        ) : (
                            <div>
                                <VolumeButton volume={room.gain} onClick={showVolumePopover} />
                                <IconButton onClick={showMenu}>
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        )
                    }
                />

                <ControlsPopover anchorEl={volumeAnchorEl} onClose={hideVolumePopover}>
                    <VolumeSlider value={room.gain} onChange={handleVolumeChange} />
                </ControlsPopover>

                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={hideMenu}
                    onClick={hideMenu}
                    // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                    <MenuItem onClick={showBackgroundAudioDialog}>
                        <ListItemIcon>
                            <QueueMusicIcon sx={{ color: blue[500] }} />
                        </ListItemIcon>
                        <ListItemText>{'Background Audio'}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={toggleBroadcasting}>
                        <ListItemIcon>
                            <PodcastsIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText>{isBroadcasting ? 'Stop' : 'Start'} Broadcasting</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={toggleMuffling}>
                        <ListItemIcon>
                            <MuffleIcon />
                        </ListItemIcon>
                        <ListItemText>{room.muffled ? 'Disable' : 'Enable'} Muffling</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={toggleSteaming}>
                        <ListItemIcon>
                            <StreamIcon sx={{ color: green[500] }} />
                        </ListItemIcon>
                        <ListItemText>{room.allowStreaming ? 'Disable' : 'Enable'} Streaming</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem disabled={isDefault} onClick={setAsDefault}>
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText>{'Set as Default'}</ListItemText>
                    </MenuItem>
                    <Tooltip
                        title={
                            isDefault
                                ? 'Cannot delete the default room. To delete this room, make another room default first'
                                : 'Delete'
                        }
                    >
                        <MenuItem disabled={isDefault} onClick={showConfirmDeleteDialog}>
                            <ListItemIcon>
                                <DeleteIcon />
                            </ListItemIcon>
                            <ListItemText>{'Delete'}</ListItemText>
                        </MenuItem>
                    </Tooltip>
                </Menu>

                <BackgroundAudioDialog
                    open={backgroundAudioDialog}
                    onClose={hideBackgroundAudioDialog}
                    room={room}
                    onSave={handleAudioChange}
                />

                <ConfirmDialog
                    open={confirmDeleteDialog}
                    title={'Are you sure you want to delete this room?'}
                    onConfirm={deleteRoom}
                    onCancel={hideConfirmDeleteDialog}
                />

                {participantList.length ? (
                    <CardContent>
                        <List
                            sx={{
                                maxHeight: 300,
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}
                        >
                            {participantList.map(renderParticipant)}
                        </List>
                    </CardContent>
                ) : null}
            </Card>
        </Grid>
    );
}

RoomGridItem.propTypes = {
    room: PropTypes.object.isRequired,
    participantList: PropTypes.array.isRequired,
};
RoomGridItem.defaultProps = {};

export default RoomGridItem;
