import React from 'react';
import PropTypes from 'prop-types';
import VolumeIcon from '../icons/VolumeIcon';
import { IconButton } from '@mui/material';

function VolumeButton(props) {
    const { volume, onClick } = props;
    return (
        <IconButton onClick={onClick}>
            <VolumeIcon volume={volume} />
        </IconButton>
    );
}

VolumeButton.propTypes = {
    volume: PropTypes.number.isRequired,
    onClick: PropTypes.func,
};
VolumeButton.defaultProps = {};

export default VolumeButton;
