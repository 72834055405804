import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { automods } from '@hearmecheer/shared/models';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';
import PageLayout from '../../core/layouts/PageLayout';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Skeleton,
    Stack,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import Button from '../../core/buttons/Button';
import FixedActionButton from '../../core/buttons/FixedActionButton';
import TextField from '../../core/inputs/TextField';

const useModelSnapshot = (model, context, defaultValue = null) => {
    const [item, setItem] = useState(defaultValue);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        return model.snapshot(model.makeRefFromContext(context), {
            onSnapshot: (item) => {
                setLoaded(true);
                if (item) {
                    setItem(item);
                }
            },
            onError: (err) => {
                setLoaded(true);
                if (err) {
                    setError(err);
                }
            },
        });
    }, [context, model]);

    return [item, loaded, error];
};

const AutomodFormDialog = ({ automod, onSubmit, onDelete, onClose }) => {
    const [form, setForm] = useState({});

    useEffect(() => {
        if (automod) {
            setForm(automod);
        }
    }, [automod]);

    const handleFieldChange = useCallback((e) => {
        const { type, name, value } = e.target;
        setForm((f) => ({
            ...f,
            [name]: type === 'number' ? Number(value) : value,
        }));
    }, []);

    const handlePercentChange = useCallback((e) => {
        const { name, value } = e.target;
        const [firstPart, secondPart] = name.split('.');
        setForm((f) => ({
            ...f,
            [firstPart]: {
                ...f[firstPart],
                [secondPart]: Number(value) / 100,
            },
        }));
    }, []);

    const handleSubmit = useCallback(() => {
        onSubmit(form);
    }, [onSubmit, form]);

    const handleDelete = useCallback(() => {
        onDelete(form.id);
    }, [form.id, onDelete]);

    return (
        <Dialog open={Boolean(automod)} onClose={onClose} sx={{ '& .MuiPaper-root': { maxWidth: 700 } }}>
            <DialogTitle>
                {automod?.id ? <span>Editing {automod.name}</span> : <span>Create Auto Moderator</span>}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} sx={{ mt: 1 }}>
                    <TextField label="Name" name="name" value={form.name} onChange={handleFieldChange} fullWidth />
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                        <Select name="intervalType" value={form.intervalType} onChange={handleFieldChange}>
                            <MenuItem value="every">Every</MenuItem>
                            <MenuItem value="once">Once</MenuItem>
                        </Select>
                        <TextField
                            name="interval"
                            type="number"
                            step={0.5}
                            value={form.interval}
                            onChange={handleFieldChange}
                            sx={{ width: 80 }}
                        />
                        <Select name="metric" value={form.metric} onChange={handleFieldChange} disabled>
                            <MenuItem value="reports">Reports</MenuItem>
                        </Select>
                        <Select name="action" value={form.action} onChange={handleFieldChange}>
                            <MenuItem value="lowerVoice">Lower Voice</MenuItem>
                            <MenuItem value="deafen">Deafen</MenuItem>
                        </Select>
                        <TextField
                            name="actionArgs.subtractPercentage"
                            type="number"
                            min={0}
                            max={100}
                            value={form.actionArgs?.subtractPercentage * 100}
                            onChange={handlePercentChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">By</InputAdornment>,
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            sx={{ width: 130 }}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                {onDelete && Boolean(automod?.id) && (
                    <Button color="primary" onClick={handleDelete}>
                        Delete
                    </Button>
                )}
                <Button flat onClick={onClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    {form.id ? 'Update' : 'Create'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
AutomodFormDialog.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    onDelete: PropTypes.func,
    automod: PropTypes.object,
};

const AutomodList = () => {
    const { company } = useAuthContext();
    const [modList, loaded] = useModelSnapshot(automods, { propertyId: company.id }, []);
    const [selectedMod, setSelectedMod] = useState(null);

    const showCreateDialog = useCallback(() => {
        setSelectedMod(automods.makeItem());
    }, []);

    const hideDialog = useCallback(() => {
        setSelectedMod(null);
    }, []);

    const handleSubmit = useCallback(
        async (form) => {
            await automods.set(automods.makeRef(company.id, automods.makeUniqueId(form)), form);
            hideDialog();
        },
        [company.id, hideDialog],
    );

    const handleDelete = useCallback(
        async (id) => {
            await automods.delete(automods.makeRef(company.id, id));
            hideDialog();
        },
        [company.id, hideDialog],
    );

    if (!loaded) {
        return (
            <div>
                <Skeleton width="100%" height={100} />
                <Skeleton width="100%" height={100} />
                <Skeleton width="100%" height={100} />
            </div>
        );
    }

    return (
        <Box sx={{ bgcolor: 'background.paper', p: 2, boxShadow: 2 }}>
            <List>
                {modList.map((mod, index) => (
                    <ListItem
                        key={mod.id}
                        button
                        divider={index < modList.length - 1}
                        onClick={() => setSelectedMod(mod)}
                    >
                        <ListItemText
                            primary={mod.name}
                            secondary={
                                <span>
                                    {mod.intervalType} {mod.interval} {mod.metric} {mod.action}{' '}
                                    {Object.values(mod.actionArgs).join(' ')}
                                </span>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <FixedActionButton onClick={showCreateDialog}>
                <AddIcon />
            </FixedActionButton>
            <AutomodFormDialog
                automod={selectedMod}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
                onClose={hideDialog}
            />
        </Box>
    );
};

const Home = () => (
    <PageLayout title="Auto Moderation">
        <AutomodList />
    </PageLayout>
);
export default Home;
