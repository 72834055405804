import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function ListCard(props) {
    const { icon, title, subtitle, children, actions } = props;
    return (
        <Box display="flex" component={Paper} elevation={1} p={2} mt={0.5}>
            {icon && <Box mr={2}>{icon}</Box>}
            <div>
                {title && <Typography variant="body1">{title}</Typography>}
                {subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </div>
            {children && (
                <Box ml={2} mr={2}>
                    {children}
                </Box>
            )}
            {actions && (
                <Box ml="auto" display="flex" flexShrink={0}>
                    {actions}
                </Box>
            )}
        </Box>
    );
}

ListCard.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node,
    actions: PropTypes.node,
};
ListCard.defaultProps = {};

export default ListCard;
