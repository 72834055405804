import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@mui/material/TextField';

function TextField(props) {
    const { variant, fullWidth, onEnter, fieldName, inputProps, multiline, ...textFieldProps } = props;

    const handleKeyDown = useCallback(
        (evt) => {
            const keyCode = evt.keyCode || evt.which;

            if (keyCode !== 13) {
                return;
            }

            if (!multiline) {
                return onEnter();
            }

            // meta key for macOS
            if (evt.metaKey || evt.ctrlKey) {
                return onEnter();
            }
        },
        [multiline, onEnter],
    );

    return (
        <MuiTextField
            fullWidth={fullWidth}
            onKeyDown={onEnter && handleKeyDown}
            inputProps={{ ...inputProps, 'data-field': fieldName }}
            multiline={multiline}
            {...textFieldProps}
        />
    );
}

TextField.propTypes = {
    variant: PropTypes.any, // defined by MUI
    fullWidth: PropTypes.bool,
    onEnter: PropTypes.func,
    fieldName: PropTypes.string,
    inputProps: PropTypes.object,
    multiline: PropTypes.bool,
};

export default TextField;
