import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';

const SnackbarContext = React.createContext({});
export const useSnackbar = () => React.useContext(SnackbarContext);

function SnackbarProvider(props) {
    const { children } = props;
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('error');
    const [message, setMessage] = useState('');

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const emitError = useCallback((message) => {
        setSeverity('error');
        setMessage(message);
        setOpen(true);
    }, []);

    const emitWarning = useCallback((message) => {
        setSeverity('warning');
        setMessage(message);
        setOpen(true);
    }, []);

    const emitInfo = useCallback((message) => {
        setSeverity('info');
        setMessage(message);
        setOpen(true);
    }, []);

    const emitSuccess = useCallback((message) => {
        setSeverity('success');
        setMessage(message);
        setOpen(true);
    }, []);

    const context = useMemo(
        () => ({
            emitError,
            emitInfo,
            emitSuccess,
            emitWarning,
        }),
        [emitError, emitInfo, emitSuccess, emitWarning],
    );

    return (
        <SnackbarContext.Provider value={context}>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={4000}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </SnackbarContext.Provider>
    );
}

SnackbarProvider.propTypes = {
    children: PropTypes.any,
};
SnackbarProvider.defaultProps = {};

export default SnackbarProvider;
