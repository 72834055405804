import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SimpleDialog from './SimpleDialog';
import Button from '../buttons/Button';

const ConfirmDialog = ({ open, title, body, onConfirm, onCancel }) => (
    <SimpleDialog
        open={open}
        onClose={onCancel}
        title={title}
        body={body}
        actions={
            <Box textAlign="right">
                <Button flat onClick={onCancel}>
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Confirm</Button>
            </Box>
        }
    />
);

ConfirmDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
};
ConfirmDialog.defaultProps = {};

export default ConfirmDialog;
