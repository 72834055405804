import React, { useCallback, useState } from 'react';
import debug from 'debug';
import { useTranslation } from 'react-i18next';
import { auth, functions } from '@hearmecheer/shared/firebase/client';
import FormLayout from '../layout/FormLayout';
import Button from '../../core/buttons/Button';
import TextField from '../../core/inputs/TextField';
import PasswordField from '../../core/inputs/PasswordField';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { useSnackbar } from '../../core/providers/SnackbarProvider';

const logger = debug('SignUpForm.js');

function SignUpForm() {
    const { emitError } = useSnackbar();
    const { t } = useTranslation();
    const [waiting, setWaiting] = useState(false);
    const [formFields, setFormFields] = useState({});

    const handleSubmit = useCallback(async () => {
        setWaiting(true);

        const { email, password, confirmPassword, displayName, photoURL } = formFields;

        logger('handleSubmit', {
            email,
            password,
            confirmPassword,
            displayName,
            photoURL,
        });

        if (password !== confirmPassword) {
            setWaiting(false);
            return emitError('Passwords do not match!');
        }

        try {
            logger('Creating User with Email and Password');
            await auth.createUserWithEmailAndPassword(email, password);
            logger('Success. Updating Profile...', { displayName, photoURL });
            await auth.currentUser.updateProfile({ displayName, photoURL });
            logger('Profile Updated. Updating Company Profiles...');
            await functions.httpsCallable('authUser_updateCompanyProfiles')();
            logger('Company Profiles Update');
        } catch (err) {
            emitError(err.message);
        }
        setWaiting(false);
    }, [emitError, formFields]);

    const handleFieldChange = useCallback((e) => {
        const { field } = e.target.dataset;
        const { value } = e.target;
        if (field) {
            setFormFields((f) => ({ ...f, [field]: value }));
        }
    }, []);

    return (
        <FormLayout>
            <LoadingBackdrop open={waiting} />
            <TextField
                label={t('Full Name')}
                fieldName={'displayName'}
                value={formFields.displayName}
                onChange={handleFieldChange}
            />
            <TextField label={t('Email')} fieldName={'email'} value={formFields.email} onChange={handleFieldChange} />
            {false && (
                <TextField
                    label={t('Photo URL (optional)')}
                    fieldName={'photoURL'}
                    value={formFields.photoURL}
                    onChange={handleFieldChange}
                />
            )}
            <PasswordField
                fieldName={'password'}
                value={formFields.password}
                onChange={handleFieldChange}
                onEnter={handleSubmit}
            />
            <PasswordField
                label={t('Confirm Password')}
                fieldName={'confirmPassword'}
                value={formFields.confirmPassword}
                onChange={handleFieldChange}
                onEnter={handleSubmit}
            />

            <Button color="primary" size="large" onClick={handleSubmit}>
                {t('Sign-Up')}
            </Button>
        </FormLayout>
    );
}

SignUpForm.propTypes = {};
SignUpForm.defaultProps = {};

export default SignUpForm;
