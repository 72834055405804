import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '../../core/inputs/TextField';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useSnackbar } from '../../core/providers/SnackbarProvider';

function CopyTextField(props) {
    const { value, ...textFieldProps } = props;
    const { emitSuccess } = useSnackbar();

    const handleCopySuccess = useCallback(() => {
        emitSuccess('Copied key to your clipboard');
    }, [emitSuccess]);

    return (
        <TextField
            {...textFieldProps}
            value={value}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <CopyToClipboard text={value} onCopy={handleCopySuccess}>
                            <IconButton>
                                <FileCopyIcon />
                            </IconButton>
                        </CopyToClipboard>
                    </InputAdornment>
                ),
            }}
        />
    );
}

CopyTextField.propTypes = {
    value: PropTypes.any,
    gutter: PropTypes.any,
};
CopyTextField.defaultProps = {};

export default CopyTextField;
