import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';

const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

const getFirstChar = (str) => {
    // 1114111 https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Not_a_codepoint
    // this will throw with invalid code points
    const codePoint = str.codePointAt(0);
    return isNaN(codePoint) ? '?' : String.fromCodePoint(codePoint);
};

const UserAvatar = React.forwardRef(function UserAvatar(props, ref) {
    const { name, icon, ...avatarProps } = props;
    const [initials, setInitials] = useState('');

    useEffect(() => {
        if (!name || !name.length) {
            return;
        }
        const nameParts = name.toUpperCase().trim().split(' ');
        let initials = getFirstChar(nameParts[0]);
        if (nameParts.length > 1 && nameParts[1].length) {
            initials += getFirstChar(nameParts[1]);
        }
        setInitials(initials);
    }, [name]);

    return (
        <Avatar {...avatarProps} ref={ref} sx={{ bgcolor: stringToColor(name) }}>
            {icon || initials}
        </Avatar>
    );
});

UserAvatar.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.element,
};
UserAvatar.defaultProps = {
    name: '',
};

export default UserAvatar;
