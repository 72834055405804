import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { keys } from '@hearmecheer/shared/models';
import { firebase } from '@hearmecheer/shared/firebase/client';
import {
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Typography,
    Menu,
    MenuItem,
    Stack,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    MoreVert as MoreVertIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    Block as BlockIcon,
} from '@mui/icons-material';
import { red } from '@mui/material/colors';
import PageLayout from '../../core/layouts/PageLayout';
import FixedActionButton from '../../core/buttons/FixedActionButton';
import TextField from '../../core/inputs/TextField';
import Button from '../../core/buttons/Button';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

const AllowedIcon = () => <CheckCircleOutlineIcon color="success" />;
const BlockedIcon = () => <BlockIcon sx={{ color: red[500] }} />;

const AddSiteDialog = () => {
    const { company } = useAuthContext();
    const { emitError } = useSnackbar();
    const [site, setSite] = useState('');
    const [open, setOpen] = useState(false);

    const openDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        setOpen(false);
    }, []);

    const handleSiteChange = useCallback((e) => {
        setSite(e.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        try {
            const { origin } = new URL(site);
            keys.update(keys.embed(company.id), {
                allowedSites: firebase.firestore.FieldValue.arrayUnion(origin),
            });
            setOpen(false);
        } catch (err) {
            emitError('The Site must be a Valid URL');
        }
    }, [company.id, site, emitError]);

    return (
        <div>
            <FixedActionButton onClick={openDialog}>
                <AddIcon />
            </FixedActionButton>
            <Dialog open={open} onClose={closeDialog}>
                <DialogTitle>Add New Domain</DialogTitle>
                <DialogContent>
                    <TextField value={site} onChange={handleSiteChange} placeholder="URL from your site" />
                </DialogContent>
                <DialogActions>
                    <Button flat onClick={closeDialog}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const SiteMenu = ({ origin, listName }) => {
    const { company } = useAuthContext();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = useCallback((e) => {
        setAnchorEl(e.target);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const moveToAllowed = useCallback(() => {
        keys.update(keys.embed(company.id), {
            [listName]: firebase.firestore.FieldValue.arrayRemove(origin),
            allowedSites: firebase.firestore.FieldValue.arrayUnion(origin),
        });
    }, [company.id, listName, origin]);

    const moveToBlocked = useCallback(() => {
        keys.update(keys.embed(company.id), {
            [listName]: firebase.firestore.FieldValue.arrayRemove(origin),
            blockedSites: firebase.firestore.FieldValue.arrayUnion(origin),
        });
    }, [company.id, origin, listName]);

    const removeFromRecords = useCallback(() => {
        keys.update(keys.embed(company.id), {
            [listName]: firebase.firestore.FieldValue.arrayRemove(origin),
        });
    }, [company.id, listName, origin]);

    return (
        <div>
            <IconButton edge="end" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
                {listName === 'allowedSites' ? null : (
                    <MenuItem onClick={moveToAllowed}>
                        <ListItemIcon>
                            <AllowedIcon />
                        </ListItemIcon>
                        Move to Allowed
                    </MenuItem>
                )}
                {listName === 'blockedSites' ? null : (
                    <MenuItem onClick={moveToBlocked}>
                        <ListItemIcon>
                            <BlockedIcon />
                        </ListItemIcon>
                        Move to Blocked
                    </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={removeFromRecords}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Remove from Records
                </MenuItem>
            </Menu>
        </div>
    );
};
SiteMenu.propTypes = {
    origin: PropTypes.string,
    listName: PropTypes.string,
};

const SiteListItem = ({ origin, divider, listName }) => (
    <ListItem divider={divider}>
        <ListItemText primary={origin} />
        <ListItemSecondaryAction>
            <SiteMenu origin={origin} listName={listName} />
        </ListItemSecondaryAction>
    </ListItem>
);
SiteListItem.propTypes = {
    origin: PropTypes.string,
    divider: PropTypes.bool,
    listName: PropTypes.string,
};

const SiteList = ({ list, title, listName }) => (
    <div>
        <Stack direction="row" spacing={1} mb={1}>
            {listName === 'allowedSites' ? <AllowedIcon /> : listName === 'blockedSites' ? <BlockedIcon /> : null}
            <Typography variant="h4">{title}</Typography>
        </Stack>
        {!list.length ? (
            <Typography variant="body1" sx={{ color: 'grey.500', fontStyle: 'italic' }}>
                None
            </Typography>
        ) : (
            <List component={Paper}>
                {list.map((origin, index) => (
                    <SiteListItem key={origin} origin={origin} listName={listName} divider={index < list.length - 1} />
                ))}
            </List>
        )}
    </div>
);
SiteList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    listName: PropTypes.oneOf(['allowedSites', 'blockedSites', 'unlistedSites']),
};

const Layout = ({ allowedSites, blockedSites, unlistedSites }) => (
    <PageLayout title="Domains">
        <AddSiteDialog />
        <Stack spacing={4}>
            <SiteList title="Allowed Sites" list={allowedSites} listName="allowedSites" />
            <Divider />
            <SiteList title="Blocked Sites" list={blockedSites} listName="blockedSites" />
            <Divider />
            <SiteList title="Unlisted Sites" list={unlistedSites} listName="unlistedSites" />
        </Stack>
    </PageLayout>
);
Layout.propTypes = {
    allowedSites: PropTypes.arrayOf(PropTypes.string),
    blockedSites: PropTypes.arrayOf(PropTypes.string),
    unlistedSites: PropTypes.arrayOf(PropTypes.string),
};

const DomainList = () => {
    const { company } = useAuthContext();
    const [embedSettings, setEmbedSettings] = useState(null);

    useEffect(() => {
        return keys.snapshot(keys.embed(company.id), { onSnapshot: setEmbedSettings });
    }, [company.id]);

    return !embedSettings ? (
        <LoadingBackdrop />
    ) : (
        <Layout
            allowedSites={embedSettings.allowedSites}
            blockedSites={embedSettings.blockedSites}
            unlistedSites={embedSettings.unlistedSites}
        />
    );
};

DomainList.propTypes = {};
DomainList.defaultProps = {};

export default DomainList;
