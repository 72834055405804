import debug from 'debug';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ApiKey from './pages/ApiKey';
import CompanyForm from './pages/CompanyForm';
import DomainList from './pages/DomainList';
import EventList from './pages/EventList';
import UserForm from './pages/UserForm';
import UserList from './pages/UserList';
import Handle404 from '../core/pages/Handle404';
import { accountRoutes, apiRoutes, eventRoutes, profileRoutes } from '../core/RoutesMap';
import { useAuthContext } from '../auth/providers/AuthContextProvider';

const logger = debug('admin/Routes.js');

const BaseRoutes = () => (
    <Switch>
        <Route exact path={accountRoutes.create} component={CompanyForm} />
        <Route path={'*'} component={Handle404} />
    </Switch>
);

const ModeratorRoutes = () => (
    <Switch>
        <Redirect exact from={eventRoutes.home} to={eventRoutes.list} />
        <Route exact path={eventRoutes.list} component={EventList} />
        <Route component={BaseRoutes} />
    </Switch>
);

const AdminRoutes = () => (
    <Switch>
        <Route exact path={profileRoutes.list} component={UserList} />
        <Route exact path={profileRoutes.create} component={UserForm} />
        <Route exact path={profileRoutes.edit} component={UserForm} />

        <Route exact path={apiRoutes.keys} component={ApiKey} />
        <Route exact path={apiRoutes.sites} component={DomainList} />

        <Route component={ModeratorRoutes} />
    </Switch>
);

const OwnerRoutes = () => (
    <Switch>
        <Route component={AdminRoutes} />
    </Switch>
);

const SuperAdminRoutes = () => (
    <Switch>
        <Route exact path={accountRoutes.create} component={CompanyForm} />
        <Route exact path={accountRoutes.edit} component={CompanyForm} />

        <Route component={OwnerRoutes} />
    </Switch>
);

const RoleBasedRoutes = () => {
    const { isAuthed, company, hasRole } = useAuthContext();
    logger('Request Hit');

    if (!isAuthed) {
        logger('User Unauthenticated - Handle404');
        return <Handle404 />;
    }

    if (!company.id) {
        logger('No Profiles - BaseRoutes');
        return <BaseRoutes />;
    }

    if (hasRole('super')) {
        logger('SuperAdminRoutes');
        return <SuperAdminRoutes />;
    }

    if (hasRole('owner')) {
        logger('OwnerRoutes');
        return <OwnerRoutes />;
    }

    if (hasRole('admin')) {
        logger('AdminRoutes');
        return <AdminRoutes />;
    }

    if (hasRole('moderator')) {
        logger('ModeratorRoutes');
        return <ModeratorRoutes />;
    }

    return 'Unhandled condition';
};

export default RoleBasedRoutes;
