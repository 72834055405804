import React from 'react';
import Icon from './FacebookIcon.png';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
}));

function FacebookIcon(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return <img src={Icon} alt={t('Facebook Icon')} className={classes.root} />;
}

FacebookIcon.propTypes = {};
FacebookIcon.defaultProps = {};

export default FacebookIcon;
