import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Box, Link, Stack } from '@mui/material';
import { keys } from '@hearmecheer/shared/models';
import PageLayout from '../../core/layouts/PageLayout';
import LoadingBackdrop from '../../core/layouts/LoadingBackdrop';
import ApiKeyField from '../components/ApiKeyField';
import { useSnackbar } from '../../core/providers/SnackbarProvider';
import ConfirmDialog from '../../core/dialogs/ConfirmDialog';
import { useAuthContext } from '../../auth/providers/AuthContextProvider';

function ApiKey() {
    const { company } = useAuthContext();
    const { emitError } = useSnackbar();
    const { t } = useTranslation();
    const [regenTarget, setRegenTarget] = useState(null);
    const [apiKeys, setApiKeys] = useState(null);

    useEffect(() => {
        return keys.makeRef(company.id, 'api').onSnapshot((docRef) => {
            if (docRef.exists) {
                setApiKeys(docRef.data());
            } else {
                emitError('No API keys yet for this property');
            }
        });
    }, [company.id, emitError]);

    const confirmRegen = useCallback((evt) => {
        const { field } = evt.currentTarget.dataset;
        setRegenTarget(field);
    }, []);

    const cancelRegen = useCallback(() => {
        setRegenTarget(null);
    }, []);

    const regenerateApiKey = useCallback(() => {
        keys.makeRef(company.id, 'api').update(regenTarget, uuid().replace(/-/g, ''));
        setRegenTarget(null);
    }, [company.id, regenTarget]);

    if (apiKeys === null) {
        return <LoadingBackdrop />;
    }

    return (
        <PageLayout
            title={t('API Keys')}
            subtitle={
                <div>
                    {t('These Keys are used to communicate with ')}
                    <Link href={process.env.REACT_APP_DOCS_SERVER}>{t('our API')}</Link>
                    <p>
                        <strong>Property ID:</strong> {company.id}
                    </p>
                </div>
            }
        >
            <Stack spacing={4}>
                <ApiKeyField
                    label="Access Key 1"
                    value={apiKeys.accessKey1}
                    field="accessKey1"
                    onRegenerate={confirmRegen}
                />
                <ApiKeyField
                    label="Access Key 2"
                    value={apiKeys.accessKey2}
                    field="accessKey2"
                    onRegenerate={confirmRegen}
                />
            </Stack>
            <ConfirmDialog
                open={Boolean(regenTarget)}
                title="Confirm Token Regeneration"
                body={
                    <div>
                        <div>
                            Are you sure you want to do this? Make sure you&apos;ve swapped this key with the alternate
                            key to reduce downtime
                        </div>
                        <Box mt={1}>
                            Continuing <strong>may break</strong> your connected apps if unprepared.
                        </Box>
                    </div>
                }
                onCancel={cancelRegen}
                onConfirm={regenerateApiKey}
            />
        </PageLayout>
    );
}

ApiKey.propTypes = {};
ApiKey.defaultProps = {};

export default ApiKey;
