import './style.scss';
import './i18n';
import * as serviceWorker from './serviceWorker';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { db } from '@hearmecheer/shared/firebase/client';
import { core } from '@hearmecheer/shared/models';

import App from './core/App';

// tell our models where the db connection is at
core.useDb(db);

function shouldRedirectToHttps() {
    if (window.location.protocol === 'https:') {
        return false;
    }
    if (process.env.REACT_APP_FORCE_SSL === 'true') {
        return true;
    }
    if (process.env.REACT_APP_FORCE_SSL === 'false') {
        return false;
    }
    return process.env.NODE_ENV !== 'development';
}

if (shouldRedirectToHttps()) {
    window.location = 'https:' + window.location.href.substring(window.location.protocol.length);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
