import React from 'react';
import debug from 'debug';
import SnackbarProvider from './providers/SnackbarProvider';
import Routes from './Routes';
import ThemeProvider from './providers/ThemeProvider';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from '../admin/providers/AppContextProvider';
import DrawerNav from './layouts/DrawerNav';
import AuthContextProvider from '../auth/providers/AuthContextProvider';

const logger = debug('core/App.js');
logger('App initialized');

const AppRoutes = () => (
    <BrowserRouter>
        <AppContextProvider>
            <DrawerNav>
                <Routes />
            </DrawerNav>
        </AppContextProvider>
    </BrowserRouter>
);

const CoreApp = () => {
    logger('App Boot');
    return (
        <ThemeProvider>
            <SnackbarProvider>
                <AuthContextProvider>
                    <AppRoutes />
                </AuthContextProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default CoreApp;
